import { Component, OnInit } from "@angular/core";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "vex-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  constructor(private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.createLinkForCanonicalURL();
    this.seoService.setPageTitle(
      "WMG - Retail Group -  Soluções B2B para Empresas e Eventos"
    );
    this.seoService.addMetaTags({
      description:
        "A WMG Retail Group é a escolha ideal para quem procura soluções completas de comunicação visual, hotelaria, restauração, armazenamento, escritório e mobiliário de design inovador. Marcas Signalshop, Racks, Maishotel, Clippy e Madedesign.",
      // image: "",
      image_meta_title:
        "WMG - Retail Group -  Soluções B2B para Empresas e Eventos",
    });
  }
}

import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "vex-expositores",
  templateUrl: "./expositores.component.html",
  styleUrls: ["./expositores.component.scss"],
})
export class ExpositoresComponent implements OnInit {
  constructor(private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.setPageTitle(
      "WMG Retail Group - Signal Shop - Expositores"
    );
    this.seoService.addMetaTags({
      description:
        "Descubra os melhores expositores em acrílicos, vitrines, postes separadores, painéis LED na loja online, Signal Shop. Tem tudo o que precisa para destacar o seu negócio.",
      image_meta_title:
        "Signal Shop Expositores, Postes Separadores, Vitrines, Display LED",
    });

    AOS.init();
  }
}

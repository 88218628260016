<div class="pt-[5vw] pb-[5vw]">
  <h2>clientes</h2>
  <h3>que confiam nos nossas soluções</h3>

  <div id="client_list" class="grid grid-cols-6 gap-8 p-8 mt-8 ">
    <div data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-00.webp" /></div>
    <div data-aos="zoom-in-up"  class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-01.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-02.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-04.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-07.webp" /></div>
    <div data-aos="zoom-in-up"  class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-11.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-17.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-19.webp" /></div>
    <div data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-21.webp" /></div>
    <div data-aos="zoom-in-up"  class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-23.webp" /></div>
    <div data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-24.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-25.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-26.webp" /></div>
    <div data-aos="zoom-in-up"  class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-00.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-01.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-02.webp" /></div>
    <div  data-aos="zoom-in-up" class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-04.webp" /></div>
    <div data-aos="zoom-in-up"  class=" flex justify-center align-middle"> <img src="assets/img/webp/clients-07.webp" /></div>
  </div>

  <swiper-container fmSwiper navigation="true" loop="true" init="false" autoplay class="mt-12 w-full">
    <swiper-slide class="w-[310px] sm:w-[450px] md:w-[450px] pb-6" *ngFor="let slider of sliders">
      <div class="sw-content">
        <p class="italic">“ {{ slider.comment }} ”</p>
        <p class="tb">{{ slider.company }}</p>
      </div>
    </swiper-slide>
  </swiper-container>
</div>
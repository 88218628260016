import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VexModule } from "../@vex/vex.module";

import { MatDialogModule } from "@angular/material/dialog";
import { ScrollbarModule } from "src/@vex/components/scrollbar/scrollbar.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { PageLayoutModule } from "src/@vex/components/page-layout/page-layout.module";

//Components
import { HeaderComponent } from "./components/header/header.component";
import { ServicesComponent } from "./components/services/services.component";
import { LandingPageComponent } from "./pages/landing-page/landing-page.component";
import { CompanyComponent } from "./components/company/company.component";
import { ClientsComponent } from "./components/clients/clients.component";
import { ContactsComponent } from "./components/contacts/contacts.component";
import { ContactsPageComponent } from "./pages/contacts-page/contacts-page.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ExpositoresComponent } from "./pages/expositores/expositores.component";
import { ArmazenamentoComponent } from "./pages/armazenamento/armazenamento.component";

//form
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatInputModule } from "@angular/material/input";

//icon
import { MatIconModule } from "@angular/material/icon";

//swipper
import { register } from "swiper/element/bundle";
import { SwiperDirective } from "./swipper.directive";
import { HotelariaComponent } from "./pages/hotelaria/hotelaria.component";
import { EscritoriosComponent } from "./pages/escritorios/escritorios.component";
import { DecoracaoComponent } from "./pages/decoracao/decoracao.component";
import { VantajsComponent } from "./components/vantajs/vantajs.component";
import { PoliticaPrivacidadeComponent } from './pages/politica-privacidade/politica-privacidade.component';
import { PoliticaCookiesComponent } from './pages/politica-cookies/politica-cookies.component';
import { HttpClientModule } from "@angular/common/http";
register();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ServicesComponent,
    LandingPageComponent,
    CompanyComponent,
    ClientsComponent,
    ContactsComponent,
    ContactsPageComponent,
    FooterComponent,
    ExpositoresComponent,
    ArmazenamentoComponent,
    HotelariaComponent,
    EscritoriosComponent,
    ExpositoresComponent,
    DecoracaoComponent,
    VantajsComponent,
    ContactsPageComponent,
    PoliticaPrivacidadeComponent,
    PoliticaCookiesComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    VexModule,
    ScrollbarModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatInputModule,
    SwiperDirective,
    BrowserModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

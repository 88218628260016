import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Location, ViewportScroller } from "@angular/common";



@Component({
  selector: "vex-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  
  scroll = false;
  s_option = false;
  route: string;
  menu_telm:boolean = false;
  ser_selected: boolean =false;
  color:string= "#fff";

  constructor(
    private router:Router, private location: Location,
    private viewportScroller: ViewportScroller) { }
 

  ngOnInit(): void {
    
    this.viewportScroller.scrollToPosition([0, 0]);


    console.log( this.location.path() );
    if( this.location.path() == ''){
      this.updateLink('company');
    }

    if( this.location.path() == '/escritorios'){
      this.updateLink('services');
    }

    if( this.location.path() == '/armazenamento'){
      this.updateLink('services');
    }

    if( this.location.path() == '/decoracao'){
      this.updateLink('services');
    }

    if( this.location.path() == '/expositores'){
      this.updateLink('services');
    }

    if( this.location.path() == '/hotelaria'){
      this.updateLink('services');
    }

    if( this.location.path() == '/contactos'){
      this.updateLink('contacts');
    }

  }
 

  
 


  openServiceOption() {
    if (this.s_option == true) {
      this.s_option = false;
      console.log("aqui");

    } else {
      this.s_option = true;

    }
  }
  updateLink(id:string){
    this.s_option = false;
    
    if ( id == "company") {
      document.getElementById('company').className = "active";
      document.getElementById('services').className = "main_option";
      document.getElementById('contacts').className = "main_option";
    }

    if ( id == "contacts") {
      document.getElementById('company').className = "main_option";
      document.getElementById('services').className = "main_option";
      document.getElementById('contacts').className = "active";

    } 
     if ( id == "services") {

      document.getElementById('company').className = "main_option";
      document.getElementById('services').className = "active";
      document.getElementById('contacts').className = "main_option";

      this.s_option = false ;

    }
   


  }

  showMenuTelm(){
    if(this.menu_telm == false){
      this.menu_telm = true;
      document.getElementById('color').style.fill='#2ed9c3';
    }else{
      this.menu_telm = false;
      document.getElementById('color').style.fill='#fff';
    }
  }

  showOptionService(){
    if(this.ser_selected == false){
      this.ser_selected = true;
      document.getElementById('t_services').className='lable_bl';
    }else{
      this.ser_selected = false;
      document.getElementById('t_services').className='t_main';

    }
  }

  closeMenu(){
    this.menu_telm =false;
    this.ser_selected=false;
    document.getElementById('t_services').className='t_main';
    document.getElementById('color').style.fill='#fff';
  }
}

import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { SwiperOptions } from "swiper";

@Directive({
  selector: "[fmSwiper]",
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  private readonly swiperElement: HTMLElement;

  @Input("config")
  config?: SwiperOptions;

  constructor(private el: ElementRef<HTMLElement>) {
    this.swiperElement = el.nativeElement;
  }

  ngAfterViewInit() {
    Object.assign(this.el.nativeElement, {
      ...this.config,
      injectStyles: [
        `
        .swiper-button-next:after, .swiper-button-prev:after {
          content: url(/assets/img/icons/arrow_right_alt_FILL0_wght600_GRAD0_opsz48.svg);
          filter: brightness(0) invert(1);
        }

        .swiper-button-prev:after {
          transform: rotate(180deg);
        }
        @media only screen and (max-width: 1000px) {
          .swiper-button-next:after, .swiper-button-prev:after {
            display:none;
          }
        }

      `,
      ],
    });

    // @ts-ignore
    this.el.nativeElement.initialize();
  }
}

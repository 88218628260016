<div class="initSpace"></div>

<div class="space"></div>
<h3>soluções para</h3>

<h2 class="pt-4">expositores</h2>
<div class="overflow-hidden">
  <img
    data-aos="zoom-out-up"
    id="armazenamento_1"
    src="/assets/img/webp/espositores_1.webp"
  />
  <img
    data-aos="zoom-out-up"
    id="armazenamento_mob"
    src="/assets/img/webp/expositor_mob.webp"
  />
</div>
<div>
  <p data-aos="zoom-in-up" data-aos-duration="1000" class="sobreText">
    A Signalshop® proporciona soluções de comunicação visual, ao disponibilizar
    uma variedade de produtos como expositores, displays para stands, eventos e
    pontos de venda. São produtos com designs inovadores que ajudam a destacar a
    sua marca e a atrair a atenção dos seus clientes, realizados com materiais
    de alta qualidade.

    <br /><br />

    <a class="link" href="/">voltar</a> <strong> |</strong
    ><a class="link" href="https://expositores.pt/"> visitar website </a>
  </p>
</div>
<div class="flex flex-row items-center overflow-hidden">
  <img
    id="armazenamento_2"
    data-aos="zoom-in-right"
    data-aos-duration="1000"
    src="/assets/img/webp/expositores_2.webp"
  />
  <div
    data-aos="zoom-in-left"
    data-aos-duration="1000"
    class="w-full flex flex-cols justify-center items-center"
  >
    <a class="link" href="https://expositores.pt/">
      <svg
        class="logo"
        id="solutions"
        width="40vw"
        viewBox="0 0 314 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_10_296)">
          <path
            d="M163.346 31.0242H147.053C146.381 31.0242 145.853 31.563 145.853 32.2266V33.5338C145.853 35.9836 147.561 38.0043 149.643 38.0043H163.346C164.915 38.0043 166.225 36.4427 166.225 34.4269C166.23 32.5809 164.915 31.0242 163.346 31.0242Z"
            fill="white"
          />
          <path
            d="M147.053 28.0155H151.371C152.044 28.0155 152.592 27.4716 152.592 26.7931V3.83682C152.592 1.70634 151.068 0.658569 149.265 0.658569C147.377 0.658569 145.853 1.70634 145.853 3.83682V26.7931C145.858 27.4766 146.381 28.0155 147.053 28.0155Z"
            fill="white"
          />
          <path
            d="M35.0458 38.4285C33.2427 38.4285 31.7285 37.3907 31.7285 35.2702V3.81695C31.7285 1.69146 33.2427 0.489014 35.1405 0.489014C36.9386 0.489014 38.4578 1.69645 38.4578 3.81695V35.2702C38.4578 37.3956 36.9386 38.4285 35.0458 38.4285Z"
            fill="white"
          />
          <path
            d="M205.171 38.2139C203.368 38.2139 201.854 37.1811 201.854 35.0606V4.03146C201.854 1.90597 203.368 0.713501 205.265 0.713501C207.064 0.713501 208.583 1.90597 208.583 4.03146V35.0556C208.578 37.1811 207.059 38.2139 205.171 38.2139Z"
            fill="white"
          />
          <path
            d="M19.341 0.483972C17.9363 0.16964 16.5267 0 15.1022 0C12.9952 0 11.1473 0.289386 9.53346 0.853188C7.90468 1.42198 6.54986 2.2153 5.44408 3.22815C4.33831 4.24598 3.51147 5.44344 2.9287 6.84048C2.37083 8.23751 2.08691 9.7493 2.08691 11.3858C2.08691 13.197 2.37083 14.7287 2.9287 15.9511C3.51645 17.1785 4.26858 18.2063 5.23488 19.0296C6.19621 19.8479 7.30198 20.5015 8.54224 20.9755C9.37406 21.2948 10.2109 21.5742 11.0925 21.7987C11.74 21.9783 12.4075 21.5542 12.5569 20.9056L13.5531 16.6696C13.6726 16.0858 13.3688 15.4821 12.8059 15.2776C11.8845 14.9333 11.1074 14.4992 10.4948 13.9803C9.57828 13.207 9.13 12.1791 9.13 10.842C9.13 10.2832 9.21966 9.71936 9.38901 9.14059C9.57828 8.5718 9.87714 8.07286 10.3105 7.64876C10.7289 7.21468 11.3017 6.88039 12.014 6.61096C12.7213 6.34153 13.6079 6.20682 14.6738 6.20682C15.5305 6.20682 16.2926 6.29663 16.9651 6.46627C17.6375 6.65587 18.2402 6.88039 18.7781 7.14483C19.3061 7.40428 19.7942 7.70863 20.2226 8.04292C20.636 8.3822 21.0544 8.71649 21.4429 9.03581C21.8364 9.42 22.2299 9.67446 22.6234 9.80917C23.0219 9.94389 23.4503 9.99877 23.9533 9.99877C24.556 9.95885 25.094 9.76926 25.5572 9.43497C26.0204 9.09569 26.3592 8.68656 26.5684 8.18762C26.8274 7.57392 26.9369 7.00014 26.9369 6.46128C26.9369 5.50331 26.5883 4.64015 25.871 3.85182C25.1637 3.0635 24.2472 2.37995 23.1315 1.82113C22.0108 1.25234 20.7506 0.798305 19.341 0.483972Z"
            fill="white"
          />
          <path
            d="M24.0383 19.329C23.0221 18.5207 21.8915 17.902 20.6412 17.4579C19.8144 17.1586 18.9925 16.8941 18.1557 16.6646C17.5032 16.49 16.8308 16.9091 16.6814 17.5677L15.73 21.644C15.5905 22.2577 15.9492 22.8864 16.5469 23.071C17.6925 23.4352 18.614 23.8793 19.2615 24.4481C20.188 25.2564 20.6363 26.4638 20.6363 28.0604C20.6363 29.5622 20.1481 30.7248 19.127 31.563C18.1159 32.4012 16.6963 32.8303 14.8882 32.8303C13.9568 32.8303 13.1549 32.7455 12.4575 32.6058C11.7602 32.4611 11.1425 32.2665 10.6195 32.012C10.0866 31.7825 9.59347 31.4931 9.17506 31.2137C8.74172 30.9293 8.33328 30.63 7.89994 30.3156C7.35203 29.9165 6.88382 29.5872 6.45546 29.3327C6.02211 29.0882 5.53398 28.9685 4.96615 28.9685C4.07456 28.9685 3.30749 29.3876 2.64004 30.2258C1.98255 31.0541 1.65381 32.007 1.65381 33.0798C1.65381 33.5138 1.80324 33.9679 2.1021 34.4568C2.40095 34.9657 2.98373 35.5096 3.84543 36.0734C4.88147 36.7919 6.24626 37.4355 7.95473 38.0292C9.65324 38.618 11.7602 38.9174 14.2955 38.9174C16.1534 38.9174 17.8718 38.6928 19.4707 38.2388C21.0646 37.7947 22.4593 37.0912 23.6547 36.1532C24.8601 35.2102 25.7965 33.9978 26.474 32.5159C27.1514 31.0391 27.4751 29.2579 27.4751 27.1523C27.4751 25.2314 27.1563 23.6398 26.5088 22.3974C25.8713 21.1451 25.0494 20.1223 24.0383 19.329Z"
            fill="white"
          />
          <path
            d="M141.156 32.2864L131.961 4.28586C131.049 1.54168 128.644 -0.134757 126.223 0.089766C123.991 0.089766 121.86 1.77619 121.058 4.28586L115.897 19.9177C115.658 20.6212 115.867 21.1301 116.53 21.1301H121.745C122.412 21.1301 122.746 20.5912 122.95 19.9177L126.492 8.3622L131.428 24.4031L115.255 24.3932C114.582 24.3932 114.328 24.5977 114.104 25.3511L111.808 32.2914C111.619 32.7853 111.569 33.2943 111.569 33.7932C111.569 35.9137 112.993 38.0392 115.125 38.0392C116.61 38.0392 117.925 37.0263 118.448 35.4198L119.723 31.3883H133.286L134.526 35.4198C135.044 37.0263 136.364 38.0392 137.784 38.0392C139.96 38.0392 141.4 35.8588 141.4 33.7932C141.405 33.2893 141.3 32.7804 141.156 32.2864Z"
            fill="white"
          />
          <path
            d="M96.835 18.0416C96.5859 17.6425 86.9727 2.2352 86.9727 2.2352C86.111 0.838162 84.6814 0.0598145 83.2718 0.0598145C80.7515 0.0598145 78.8438 2.51959 78.8438 5.14402V34.9308C78.8438 37.0513 80.3679 37.9943 82.171 37.9943C84.0837 37.9943 85.588 37.0563 85.588 34.9308V13.3566C85.588 13.3566 96.2124 30.5151 96.3967 30.7995C96.6308 31.1737 97.1339 31.0939 97.1339 30.605V19.2191C97.1388 18.79 97.0791 18.4508 96.835 18.0416Z"
            fill="white"
          />
          <path
            d="M103.719 38.0243C101.926 38.0243 100.396 37.0564 100.396 34.9309V3.77203C100.396 1.64155 101.931 0.484009 103.818 0.484009C105.622 0.484009 107.136 1.64654 107.136 3.77203V34.9309C107.131 37.0514 105.617 38.0243 103.719 38.0243Z"
            fill="white"
          />
          <path
            d="M56.9175 32.2665C56.3795 32.1168 55.8665 31.9023 55.3933 31.6278C54.2427 30.9692 53.2814 30.0512 52.5143 28.8737C51.7423 27.7012 51.1794 26.2992 50.8058 24.6726C50.4323 23.0561 50.2529 21.2948 50.2529 19.3539C50.2529 15.3324 50.9951 12.1841 52.5193 9.87903C54.0285 7.57891 56.3447 6.42636 59.4329 6.42636C63.318 6.42636 65.166 8.41713 66.989 10.4827C67.6166 11.2162 68.4783 11.6004 69.3002 11.6004C70.9887 11.6004 72.5228 9.97383 72.5228 8.02297C72.5228 7.19473 72.2837 6.29165 71.7558 5.62307C71.3274 5.10916 70.8741 4.6202 70.3611 4.15119C69.2254 3.0635 67.8955 2.15543 66.3962 1.48186C65.5395 1.09269 64.628 0.793321 63.6667 0.583766C63.5173 0.54884 63.3728 0.518903 63.2084 0.478988L63.1337 0.46402C62.3467 0.289391 61.5249 0.184613 60.718 0.169645C60.2846 0.139709 59.8662 0.119751 59.4279 0.119751C56.9374 0.119751 54.691 0.588755 52.6837 1.51678C50.6614 2.43484 48.9629 3.74206 47.5533 5.45343C46.1586 7.13985 45.0877 9.17552 44.3306 11.5605C43.5834 13.9454 43.2148 16.6097 43.2148 19.5285C43.2148 22.3825 43.5735 24.9969 44.2858 27.3819C44.9881 29.7718 46.039 31.8324 47.4238 33.5438C48.8085 35.2751 50.512 36.6322 52.5193 37.5902C53.6998 38.154 54.9948 38.5482 56.3795 38.7876C57.1317 38.9024 57.7891 38.3336 57.7891 37.5752V33.4191C57.7842 32.8852 57.4455 32.4062 56.9175 32.2665Z"
            fill="white"
          />
          <path
            d="M73.499 21.7488C73.499 19.3589 71.8104 17.438 69.7085 17.438H67.3226H60.8274C59.2285 17.438 58.1377 18.9847 58.1377 20.8956C58.1377 22.8116 59.2285 24.3633 60.8274 24.3633H66.954C66.9341 24.4631 66.9142 24.5778 66.8992 24.6676C66.5605 26.2942 66.0624 27.6962 65.38 28.8687C64.7126 30.0462 63.8658 30.9643 62.8646 31.6179C62.4313 31.9023 61.983 32.1068 61.4998 32.2565C61.0267 32.4062 60.7178 32.8802 60.7178 33.424V37.5353C60.7178 38.3087 61.3405 38.8725 61.9979 38.7428C63.1585 38.4933 64.2195 38.1141 65.2256 37.5553C66.9889 36.5624 68.4931 35.2003 69.7433 33.439C70.9836 31.6728 71.9449 29.5722 72.6174 27.1574C73.0607 25.5857 73.3545 23.9142 73.509 22.1679V22.0931V21.7488"
            fill="white"
          />
          <path
            d="M263.513 5.36864C262.128 3.63732 260.425 2.30514 258.423 1.3372C257.237 0.778386 255.947 0.369255 254.547 0.149721C253.81 0.0199962 253.153 0.598767 253.153 1.34718V5.49836C253.153 6.0472 253.511 6.51121 254.009 6.6609C254.542 6.81058 255.06 7.02512 255.544 7.28956C256.694 7.95814 257.656 8.8712 258.423 10.0487C259.195 11.2262 259.767 12.6232 260.126 14.2348C260.5 15.8614 260.679 17.6376 260.679 19.5585C260.679 23.58 259.937 26.7482 258.423 29.0434C256.903 31.3435 254.597 32.4811 251.504 32.4811C248.421 32.4811 246.12 31.3435 244.59 29.0434C243.091 26.7482 242.339 23.58 242.339 19.5585C242.339 17.6376 242.533 15.8614 242.897 14.2348C243.261 12.6182 243.843 11.2262 244.59 10.0487C245.363 8.8712 246.319 7.95814 247.46 7.28956C247.933 7.02512 248.461 6.80559 249.014 6.64593C249.527 6.51121 249.87 6.02724 249.87 5.49836V1.36215C249.87 0.598767 249.168 0.029975 248.436 0.169678C247.141 0.40418 245.925 0.798343 244.785 1.36215C242.792 2.33508 241.094 3.71216 239.694 5.47342C238.29 7.23967 237.199 9.33023 236.442 11.7501C235.675 14.1749 235.291 16.8343 235.291 19.7182C235.291 22.602 235.645 25.2215 236.362 27.5715C237.074 29.9215 238.105 31.9322 239.485 33.5887C240.855 35.2402 242.548 36.5225 244.541 37.4356C246.563 38.3436 248.874 38.7927 251.504 38.7927C253.99 38.7927 256.256 38.3436 258.258 37.4006C260.271 36.4776 261.974 35.1604 263.379 33.474C264.793 31.7826 265.864 29.7469 266.601 27.357C267.344 24.972 267.717 22.3127 267.717 19.3889C267.717 16.5449 267.373 13.9205 266.651 11.5355C265.954 9.15061 264.903 7.08998 263.513 5.36864Z"
            fill="white"
          />
          <path
            d="M189.461 0.488993C188.056 0.164681 186.642 0.0100098 185.222 0.0100098C183.115 0.0100098 181.267 0.294406 179.654 0.863198C178.025 1.42201 176.67 2.22531 175.564 3.24314C174.458 4.25599 173.637 5.45844 173.049 6.8455C172.491 8.23754 172.207 9.74933 172.207 11.3908C172.207 13.197 172.491 14.7238 173.049 15.9562C173.637 17.1935 174.389 18.2114 175.355 19.0346C176.316 19.8529 177.422 20.5015 178.662 20.9805C179.489 21.3048 180.331 21.5792 181.213 21.8037C181.86 21.9834 182.528 21.5593 182.677 20.9106L183.673 16.6796C183.793 16.0909 183.489 15.4921 182.926 15.2776C182.005 14.9383 181.228 14.4943 180.615 13.9803C179.698 13.217 179.25 12.1742 179.25 10.852C179.25 10.2882 179.345 9.7144 179.509 9.14062C179.698 8.58181 179.997 8.07289 180.431 7.65877C180.849 7.22469 181.422 6.88042 182.134 6.61099C182.841 6.34656 183.728 6.20685 184.794 6.20685C185.651 6.20685 186.413 6.31163 187.085 6.47628C187.758 6.66089 188.36 6.88042 188.898 7.14985C189.426 7.41429 189.909 7.71864 190.343 8.05792C190.756 8.39221 191.175 8.71153 191.563 9.05081C191.957 9.43999 192.35 9.68946 192.744 9.81918C193.142 9.94891 193.575 10.0088 194.073 10.0088C194.676 9.97385 195.214 9.78426 195.677 9.44997C196.141 9.11069 196.479 8.68659 196.688 8.18765C196.947 7.58892 197.057 7.01514 197.057 6.48127C197.057 5.5233 196.708 4.64018 195.991 3.85684C195.284 3.07849 194.367 2.40492 193.252 1.83613C192.131 1.25736 190.876 0.808315 189.461 0.488993Z"
            fill="white"
          />
          <path
            d="M194.158 19.329C193.142 18.5257 192.011 17.897 190.761 17.458C189.934 17.1536 189.112 16.9042 188.275 16.6696C187.623 16.49 186.95 16.9241 186.801 17.5827L185.85 21.6591C185.71 22.2628 186.069 22.8964 186.666 23.086C187.812 23.4303 188.734 23.8943 189.381 24.4581C190.308 25.2564 190.756 26.4639 190.756 28.0655C190.756 29.5723 190.268 30.7348 189.247 31.563C188.23 32.4012 186.816 32.8204 185.008 32.8204C184.076 32.8204 183.274 32.7555 182.577 32.6108C181.88 32.4611 181.262 32.2665 180.739 32.0221C180.206 31.7676 179.713 31.5032 179.295 31.2188C178.861 30.9344 178.453 30.63 178.024 30.3057C177.477 29.9165 177.008 29.5922 176.58 29.3427C176.147 29.0933 175.658 28.9735 175.091 28.9735C174.199 28.9735 173.432 29.3976 172.765 30.2358C172.107 31.0691 171.778 32.0171 171.778 33.0898C171.778 33.5039 171.928 33.9779 172.227 34.4669C172.525 34.9758 173.108 35.4997 173.97 36.0834C175.006 36.7869 176.371 37.4306 178.079 38.0243C179.778 38.618 181.885 38.8925 184.42 38.8925C186.278 38.8925 187.996 38.6829 189.595 38.2339C191.189 37.7848 192.584 37.0913 193.779 36.1533C194.985 35.2003 195.921 33.9879 196.598 32.516C197.276 31.0391 197.6 29.243 197.6 27.1374C197.6 25.2215 197.281 23.6299 196.633 22.3925C195.996 21.1501 195.169 20.1273 194.158 19.329Z"
            fill="white"
          />
          <path
            d="M285.469 0.743408H276.02C275.233 0.743408 274.606 0.947974 274.123 1.40201C273.639 1.85106 273.405 2.45976 273.405 3.2431V13.9005C273.405 14.5641 273.948 15.1029 274.616 15.1029H278.76C279.422 15.1029 279.96 14.5641 279.96 13.9005V6.7756H284.931C286.032 6.7756 286.953 6.92528 287.706 7.22465C288.443 7.53898 289.036 7.9531 289.494 8.48198C289.942 9.01584 290.261 9.62954 290.445 10.2981C290.644 10.9867 290.749 11.7001 290.749 12.4286C290.749 13.2169 290.644 13.9703 290.445 14.6838C290.261 15.3973 289.942 16.0309 289.494 16.5898C289.041 17.1336 288.448 17.5577 287.706 17.897C286.953 18.2113 286.032 18.366 284.931 18.366H274.616C273.948 18.366 273.405 18.9148 273.405 19.5834V35.0356C273.405 36.1532 273.669 36.9565 274.207 37.4405C274.735 37.9494 275.607 38.1989 276.827 38.1989C278.062 38.1989 278.894 37.8995 279.323 37.2908C279.756 36.6921 279.96 35.9736 279.96 35.1504V24.3633H285.738C288.139 24.3633 290.116 23.9791 291.656 23.2307C293.19 22.4823 294.385 21.5243 295.277 20.3767C296.168 19.2242 296.766 17.9519 297.095 16.5848C297.409 15.2077 297.573 13.8705 297.573 12.5982C297.573 8.81627 296.617 5.89248 294.694 3.82686C292.771 1.75626 289.693 0.743408 285.469 0.743408Z"
            fill="white"
          />
          <path
            d="M226.923 0.713501C224.985 0.713501 223.511 1.91096 223.511 4.03146V15.3075H213.11C212.443 15.3075 211.89 15.8514 211.89 16.505V21.0753C211.89 21.7488 212.443 22.2927 213.11 22.2927L223.511 22.2877V35.0556C223.511 37.236 225.035 38.2089 226.868 38.2089C228.776 38.2089 230.295 37.236 230.295 35.0556V4.03146C230.3 1.91096 228.716 0.713501 226.923 0.713501Z"
            fill="white"
          />
          <path
            d="M43.7877 46.9303H2.69968C0.941402 46.9303 0 48.3623 0 50.1435C0 51.9047 0.941402 53.3367 2.69968 53.3367H43.7926C45.5609 53.3367 46.5023 51.9047 46.5023 50.1435C46.4973 48.3623 45.5559 46.9303 43.7877 46.9303Z"
            fill="white"
          />
          <path
            d="M306.419 5.73277C306.419 5.46334 306.628 5.25378 306.897 5.25378H308.825C309.502 5.25378 310.035 5.45336 310.384 5.79264C310.668 6.08701 310.832 6.49116 310.832 6.96016V6.97513C310.832 7.84329 310.329 8.36717 309.607 8.5917L310.643 9.89892C310.737 10.0137 310.797 10.1135 310.797 10.2631C310.797 10.5276 310.573 10.7022 310.344 10.7022C310.13 10.7022 309.99 10.6024 309.881 10.4527L308.571 8.77631H307.375V10.2282C307.375 10.4927 307.166 10.7022 306.907 10.7022C306.638 10.7022 306.429 10.4927 306.429 10.2282V5.73277H306.419ZM308.755 7.93808C309.432 7.93808 309.866 7.58384 309.866 7.035V7.02003C309.866 6.44126 309.447 6.12194 308.745 6.12194H307.365V7.93808H308.755Z"
            fill="white"
          />
          <path
            d="M308.625 13.3666C305.662 13.3666 303.251 10.9518 303.251 7.98306C303.251 5.01436 305.662 2.59949 308.625 2.59949C311.589 2.59949 314 5.01436 314 7.98306C314 10.9518 311.589 13.3666 308.625 13.3666ZM308.625 3.36287C306.08 3.36287 304.013 5.43347 304.013 7.98306C304.013 10.5326 306.08 12.6032 308.625 12.6032C311.166 12.6032 313.238 10.5326 313.238 7.98306C313.238 5.43347 311.171 3.36287 308.625 3.36287Z"
            fill="white"
          />
          <path
            d="M1.51419 60.8258C2.43567 60.756 3.79548 60.7061 4.57251 60.7061C7.50629 60.7061 8.45766 61.9035 7.93964 65.5757C7.35686 69.7269 6.14649 70.3356 3.30734 70.3356C2.42571 70.3356 1.20039 70.2807 0.199219 70.2159L1.51419 60.8258ZM1.70845 69.1232C2.29122 69.1381 2.90388 69.1531 3.29738 69.1531C5.38938 69.1531 6.06679 68.6093 6.53002 65.466C6.96337 62.4424 6.51508 61.8885 4.5476 61.8885C4.1541 61.8885 3.38205 61.8985 2.72955 61.8985L1.70845 69.1232Z"
            fill="white"
          />
          <path
            d="M9.14014 70.211L10.0616 63.5701H11.3666L10.4451 70.211H9.14014ZM11.3915 60.7411C11.6505 60.7411 11.785 60.9057 11.7452 61.1502L11.6505 61.9136C11.6107 62.173 11.5011 62.2828 11.2421 62.2828H10.6195C10.3605 62.2828 10.226 62.1182 10.2509 61.8587L10.3704 61.1003C10.3854 60.8708 10.5049 60.7461 10.7639 60.7461H11.3915V60.7411Z"
            fill="white"
          />
          <path
            d="M14.1063 66.9179C13.155 66.4689 12.7067 65.955 12.8312 64.987C12.9806 63.7197 13.8921 63.3704 15.6305 63.3954C16.5669 63.4104 17.7076 63.5052 18.4697 63.6249L18.3053 64.5629C17.4635 64.538 16.6765 64.508 15.6704 64.4931C14.5845 64.4931 14.1761 64.518 14.1113 65.0768C14.0416 65.5808 14.3554 65.7155 14.8983 65.9599L16.6915 66.7782C17.7225 67.2522 18.1459 67.6763 18.0064 68.6941C17.8022 70.2059 16.7313 70.3556 14.8684 70.3556C13.4439 70.3556 13.2645 70.2608 12.3032 70.166L12.4527 69.1881C13.0653 69.243 13.922 69.253 14.5297 69.253C16.1983 69.253 16.6317 69.1182 16.7164 68.5744C16.7961 68.0854 16.4723 68.0056 15.8895 67.7312L14.1063 66.9179Z"
            fill="white"
          />
          <path
            d="M21.2934 64.9871C22.0704 64.1838 23.2509 63.4104 24.1973 63.4104C25.7314 63.4104 26.3142 64.1738 25.9357 66.9728C25.5123 70.0064 24.4414 70.3856 22.7279 70.3856C22.1302 70.3856 21.3432 70.3157 20.6359 70.206C20.6359 70.6301 20.611 71.0093 20.5413 71.4733L20.3121 72.9302H18.9922L20.3221 63.5701H21.3532L21.2934 64.9871ZM20.8451 69.2829C21.4827 69.2979 21.9857 69.2979 22.2448 69.2979C23.9831 69.2979 24.3069 69.0285 24.6057 66.878C24.8797 64.9072 24.7004 64.553 23.8984 64.553C23.3157 64.553 22.3095 65.0719 21.3034 65.9949L20.8451 69.2829Z"
            fill="white"
          />
          <path
            d="M28.805 68.32C28.7104 68.9337 28.9146 69.1382 29.5273 69.1382H30.1499L30.2047 70.171C29.9307 70.2958 29.2533 70.3357 28.9395 70.3357C27.8935 70.3357 27.3107 69.697 27.4602 68.5694L28.5709 60.6912H29.8759L28.805 68.32Z"
            fill="white"
          />
          <path
            d="M36.0421 70.2109L36.0969 68.9885C35.4194 69.7768 34.4282 70.3756 33.2477 70.3756C31.6837 70.3756 31.091 69.5174 31.4695 66.8131C31.8879 63.7945 32.9638 63.3954 34.757 63.3954C35.7332 63.3954 37.0681 63.5451 38.0046 63.7496L37.0781 70.2109H36.0421ZM36.5601 64.523C35.9325 64.508 35.4194 64.498 35.1604 64.498C33.4071 64.498 33.0983 64.7824 32.7845 66.9079C32.5106 68.8787 32.6899 69.233 33.5167 69.233C34.3983 69.233 35.1455 68.7291 36.0819 67.816L36.5601 64.523Z"
            fill="white"
          />
          <path
            d="M45.8349 63.5701C45.4016 65.501 44.8985 67.2024 43.9173 69.0534C42.4379 71.8674 41.1329 72.6857 39.3398 73L39.0957 72.062C40.1268 71.8176 41.0084 71.4084 41.8651 70.211H41.2525C40.844 70.211 40.5352 69.9665 40.4505 69.5723L39.1754 63.5751H40.5601L41.5663 68.9486C41.5912 69.0983 41.7007 69.1782 41.8352 69.1782H42.5027C42.5973 69.0135 42.677 68.8389 42.7766 68.6593C43.5387 67.0826 44.0667 65.6806 44.4602 63.5701H45.8349Z"
            fill="white"
          />
          <path
            d="M48.0912 66.9179C47.1398 66.4689 46.6915 65.955 46.8161 64.987C46.9655 63.7197 47.877 63.3704 49.6154 63.3954C50.5518 63.4104 51.6924 63.5052 52.4545 63.6249L52.2901 64.5629C51.4484 64.538 50.6614 64.508 49.6552 64.4931C48.5694 64.4931 48.1609 64.518 48.0962 65.0768C48.0264 65.5808 48.3402 65.7155 48.8832 65.9599L50.6763 66.7782C51.7074 67.2522 52.1308 67.6763 51.9913 68.6941C51.7871 70.2059 50.7162 70.3556 48.8533 70.3556C47.4287 70.3556 47.2494 70.2608 46.2881 70.166L46.4375 69.1881C47.0502 69.243 47.9069 69.253 48.5146 69.253C50.1832 69.253 50.6165 69.1182 50.7012 68.5744C50.7809 68.0854 50.4572 68.0056 49.8744 67.7312L48.0912 66.9179Z"
            fill="white"
          />
          <path
            d="M58.6162 65.7055C57.2962 65.2315 56.9027 64.3833 57.097 63.0512C57.3958 60.9307 58.5215 60.6562 60.4491 60.6562C61.3607 60.6562 62.3519 60.7261 63.2584 60.9556L63.109 61.9335C62.2672 61.8936 61.3557 61.8637 60.4491 61.8637C59.2288 61.8487 58.6311 61.8487 58.4518 63.1809C58.3322 64.1089 58.5464 64.2836 59.3484 64.5679L61.1415 65.2066C62.5511 65.7105 62.8799 66.4739 62.6906 67.8709C62.4067 69.9515 61.4404 70.3606 59.1591 70.3606C58.6012 70.3606 57.5452 70.3357 56.2651 70.0912L56.4445 69.0834C58.143 69.1382 58.646 69.1532 59.1491 69.1532C60.6683 69.1382 61.1714 68.9786 61.3358 67.7362C61.4453 66.8132 61.2262 66.6236 60.4541 66.3492L58.6162 65.7055Z"
            fill="white"
          />
          <path
            d="M70.4856 66.9478C70.132 69.4625 69.1557 70.3905 66.9292 70.3905C64.7027 70.3905 63.8211 69.4126 64.1747 66.853C64.5134 64.3484 65.4797 63.3954 67.7461 63.3954C69.9975 63.3954 70.8542 64.3883 70.4856 66.9478ZM65.5296 66.9478C65.2855 68.769 65.5843 69.2879 66.9292 69.2879C68.3289 69.2879 68.8718 68.7191 69.1308 66.853C69.3998 65.0169 69.076 64.513 67.7461 64.513C66.3713 64.508 65.8185 65.002 65.5296 66.9478Z"
            fill="white"
          />
          <path
            d="M73.2849 68.32C73.1903 68.9337 73.3945 69.1382 74.0072 69.1382H74.6348L74.6895 70.171C74.4156 70.2958 73.7382 70.3357 73.4244 70.3357C72.3784 70.3357 71.7956 69.697 71.945 68.5694L73.0558 60.6912H74.3608L73.2849 68.32Z"
            fill="white"
          />
          <path
            d="M81.4884 70.2109H80.4673L80.547 68.8238C79.4612 69.8716 78.5397 70.3905 77.5335 70.3905C76.3132 70.3905 75.7802 69.667 75.9446 68.5394L76.637 63.575H77.9519L77.2845 68.2001C77.1749 68.9735 77.3642 69.2629 77.9918 69.2629C78.7788 69.2629 79.4163 68.7739 80.5321 67.796L81.0999 63.5799H82.4049L81.4884 70.2109Z"
            fill="white"
          />
          <path
            d="M85.5631 68.3199C85.4834 68.9436 85.6428 69.1382 86.2804 69.1382H87.2716L87.3264 70.156C86.8631 70.2907 86.0911 70.3356 85.7773 70.3356C84.5968 70.3356 84.0788 69.6571 84.2431 68.4447L84.7861 64.5928H83.5757L83.6952 63.6399L84.9305 63.57L85.2144 61.6241H86.5045L86.2355 63.57H88.2727L88.1382 64.5928H86.0861L85.5631 68.3199Z"
            fill="white"
          />
          <path
            d="M88.7705 70.211L89.692 63.5701H90.997L90.0755 70.211H88.7705ZM91.0219 60.7411C91.2809 60.7411 91.4154 60.9057 91.3756 61.1502L91.2809 61.9136C91.2411 62.173 91.1315 62.2828 90.8725 62.2828H90.2449C89.9859 62.2828 89.8514 62.1182 89.8813 61.8587L90.0008 61.1003C90.0157 60.8708 90.1353 60.7461 90.3943 60.7461H91.0219V60.7411Z"
            fill="white"
          />
          <path
            d="M98.5884 66.9478C98.2347 69.4625 97.2585 70.3905 95.032 70.3905C92.8055 70.3905 91.9238 69.4126 92.2725 66.853C92.6112 64.3484 93.5775 63.3954 95.8439 63.3954C98.1002 63.3954 98.957 64.3883 98.5884 66.9478ZM93.6323 66.9478C93.3883 68.769 93.6871 69.2879 95.032 69.2879C96.4316 69.2879 96.9746 68.7191 97.2336 66.853C97.5025 65.0169 97.1788 64.513 95.8488 64.513C94.4741 64.508 93.9162 65.002 93.6323 66.9478Z"
            fill="white"
          />
          <path
            d="M101.717 65.002C102.897 63.9392 103.878 63.3954 104.855 63.3954C105.955 63.3954 106.608 64.034 106.428 65.2614L105.721 70.2109H104.416L105.054 65.5857C105.163 64.7575 105.014 64.508 104.441 64.508C103.624 64.508 102.837 65.0668 101.697 65.9649L101.114 70.2109H99.7988L100.735 63.575H101.781L101.717 65.002Z"
            fill="white"
          />
          <path
            d="M109.307 66.9179C108.356 66.4689 107.907 65.955 108.032 64.987C108.181 63.7197 109.093 63.3704 110.831 63.3954C111.768 63.4104 112.908 63.5052 113.67 63.6249L113.506 64.5629C112.664 64.538 111.877 64.508 110.871 64.4931C109.785 64.4931 109.377 64.518 109.312 65.0768C109.242 65.5808 109.556 65.7155 110.099 65.9599L111.892 66.7782C112.923 67.2522 113.347 67.6763 113.207 68.6941C113.003 70.2059 111.932 70.3556 110.069 70.3556C108.645 70.3556 108.465 70.2608 107.504 70.166L107.653 69.1881C108.261 69.243 109.118 69.253 109.73 69.253C111.399 69.253 111.832 69.1182 111.917 68.5744C111.997 68.0854 111.673 68.0056 111.09 67.7312L109.307 66.9179Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_10_296">
            <rect width="314" height="73" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  </div>
</div>

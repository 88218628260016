import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc: any
  ) {}

  setPageTitle(title: string) {
    this.createLinkForCanonicalURL();
    this.title.setTitle(title);
  }

  getPageTitle() {
    return this.title.getTitle();
  }

  createLinkForCanonicalURL() {
    let link: HTMLLinkElement =
      this.doc.querySelector(`link[rel='canonical']`) || null;
    if (link == null) {
      link = this.doc.createElement("link");
      link.setAttribute("rel", "canonical");
      this.doc.head.appendChild(link);
    }

    link.setAttribute("href", this.doc.URL);
  }

  addMetaTags(tags) {
    const image = tags.image
      ? this.doc.baseURI + tags.image
      : this.doc.baseURI + "assets/img/meta-image.webp";

    this.meta.updateTag(
      { name: "description", content: tags.description },
      "name='description'"
    );
    this.meta.updateTag(
      { property: "og:title", content: this.getPageTitle() },
      "property='og:title'"
    );
    this.meta.updateTag(
      { property: "og:description", content: tags.description },
      "property='og:description'"
    );
    this.meta.updateTag(
      { property: "og:url", content: this.doc.URL },
      "property='og:url'"
    );
    this.meta.updateTag(
      { property: "og:site_name", content: this.getPageTitle() },
      "property='og:site_name'"
    );
    this.meta.updateTag(
      { property: "og:image", content: image },
      "property='og:image'"
    );
    this.meta.updateTag(
      { property: "og:image:secure_url", content: image },
      "property='og:image:secure_url'"
    );
    this.meta.updateTag(
      { property: "og:image:alt", content: tags.image_meta_title },
      "property='og:image:alt'"
    );
    this.meta.updateTag(
      { name: "twitter:title", content: this.getPageTitle() },
      "name='twitter:title'"
    );
    this.meta.updateTag(
      { name: "twitter:description", content: tags.description },
      "name='twitter:description'"
    );
    this.meta.updateTag(
      { name: "twitter:image", content: image },
      "name='twitter:image'"
    );
  }
}

import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "vex-hotelaria",
  templateUrl: "./hotelaria.component.html",
  styleUrls: ["./hotelaria.component.scss"],
})
export class HotelariaComponent implements OnInit {
  constructor(private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.setPageTitle(
      "WMG Retail Group - MaisHotel: Material de Hotelaria"
    );
    this.seoService.addMetaTags({
      description:
        "Encontre soluções completas para hotéis na loja online Mais Hotel. Descubra uma ampla gama de produtos e serviços projetados especificamente para atender às necessidades da indústria hoteleira.",
      image_meta_title: "MaisHotel Material de Hotelaria",
    });

    AOS.init();
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EmailService {
  private emailApiUrl = environment.formApiUrl + "/send";

  constructor(private http: HttpClient) {}

  sendEmail(params): Promise<any> {
    const emailData = {
      ...params,
      to: environment.formEmail,
      subject: "WMG website form",
    };

    const headers = new HttpHeaders({
      Authorization: environment.formApiKey,
    });

    return this.http
      .post(this.emailApiUrl, emailData, { headers: headers })
      .toPromise()
      .then((response) => {
        console.log("Email sent successfully:", response);
        return response;
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        throw error;
      });
  }

  // confirmMessage( mail: string , name: string): Promise<any> {
  //   const emailData = {
  //     to: mail,
  //     subject: 'WMG - Retail Solutions',
  //     message: "A sua",
  //     country: 'Portugal',
  //     name: name,
  //   };

  //   const headers = new HttpHeaders({
  //     'Authorization': this.apiKey
  //   });

  //   return this.http.post(this.emailApiUrl, emailData, { headers: headers })
  //     .toPromise()
  //     .then(response => {
  //       console.log('Email sent successfully:', response);
  //       return response;
  //     })
  //     .catch(error => {
  //       console.error('Error sending email:', error);
  //       throw error;
  //     });
  // }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear: number;

  constructor() { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  pdfPath = '/assets/Ficha-de-Projeto-2020-WMG.pdf';
  pdfPath2 = '/assets/FICHA-PROJETO-2025.pdf';
}


import { Component, OnInit } from "@angular/core";
import * as AOS from 'aos';

@Component({
  selector: "vex-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit{
 

  ngOnInit() {
    AOS.init();
  }
    
  sliders: string[] = [
    "agências",
    "stand automóveis",
    "museus",
    "instituições públicas",
    "hotelaria",
    "horeca",
    "feiras",
    "estações",
    "escritorios",
    "escolas",
    "centro de empresas",
    "bibliotecas",
    "bancos",
  ];
  
}



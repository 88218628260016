<div class="space"></div>
<h2 data-aos="zoom-in-up" data-aos-duration="1000">vamos falar?</h2>
<h3 data-aos="zoom-in-up" data-aos-duration="1000">
  procuramos criar relações de confiança com os nossos clientes
</h3>
<div class="" id="container_contacts">
  <div
    class="w-[50%] ml-auto mr-auto pb-28 px-28 py-28 pt-8 pb-0"
    id="form_container"
  >
    <form (ngSubmit)="send()" [formGroup]="form">
      <!-- honeypot field start-->
      <div style="display: none">
        <label>Keep this field blank</label>
        <input type="text" formControlName="honeypot" />
      </div>
      <!-- honeypot field end -->

      <mat-form-field
        class="w-full"
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
        <mat-label>nome:</mat-label>
        <input matInput formControlName="name" />
        <mat-hint class="white">ex: João Nogueira</mat-hint>
        <mat-error *ngIf="form.get('name').hasError('required')">
          Obrigatório
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="w-full"
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
        <mat-label>email:</mat-label>
        <input matInput formControlName="email" />
        <mat-hint class="white">ex: mail@exemplo.com</mat-hint>
        <mat-error *ngIf="form.get('email').hasError('required')">
          Obrigatório
        </mat-error>
      </mat-form-field>

      <mat-form-field
        class="w-full"
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
        <mat-label>mensagem:</mat-label>
        <textarea
          formControlName="message"
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="10"
        ></textarea>

        <mat-error *ngIf="form.get('message').hasError('required')">
          Obrigatório
        </mat-error>
      </mat-form-field>

      <button
        data-aos="zoom-in-up"
        data-aos-duration="1000"
        class="mt-4"
        color="primary"
        mat-button
        type="submit"
        [disabled]="isLoading"
      >
        enviar
        <mat-icon *ngIf="isLoading"
          ><mat-spinner id="spinner" diameter="20"></mat-spinner
        ></mat-icon>
      </button>
    </form>
    <div
      class="mensagem"
      *ngIf="mensagem"
      data-aos="zoom-in-up"
      data-aos-duration="1000"
    >
      <p>Mensagem enviada com sucesso!</p>

      <button (click)="closeMensage()">x</button>
    </div>
    <div
      class="mensagem"
      id="erro"
      *ngIf="erro"
      data-aos="zoom-in-up"
      data-aos-duration="1000"
    >
      <p>
        Erro no envio de mensagem.<br />
        Por favor contacte-nos via e-mail.
      </p>

      <button (click)="closeMensage()">x</button>
    </div>
  </div>
</div>

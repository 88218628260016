<div
  data-aos="zoom-in-up"
  data-aos-duration="1000"
  data-aos-anchor-placement="top-center"
  class="divText"
>
  <p class="sobreText">
    <strong>
      Bem-vindo à
      <h1 class="inline">WMG - Retail Solutions!</h1>
    </strong>

    <br /><br />
    Somos uma empresa especializada em fornecer soluções completas para
    empresas, a partir da combinação das nossas cinco marcas líderes de mercado:
    Signalshop®, Maishotel®, Racks®, Clippy® e Madedesign®. Por meio destas
    marcas, oferecemos uma ampla gama de produtos para os setores de comunicação
    visual, hotelaria, restauração, armazenamento, escritório e mobiliário de
    design inovador.
  </p>
</div>

<div id="setores">
  <div id="desktop">
    <img
      data-aos="zoom-in-up"
      data-aos-duration="1000"
      data-aos-anchor-placement="top-center"
      class="w-full"
      src="/assets/img/webp/bannersetores2.webp"
    />
  </div>
  <div id="telm">
    <img class="w-full" src="/assets/img/webp/bannersetores2-telm.webp" />
  </div>
  <div class="all" data-aos="zoom-in-up">
    <p id="lableSlider">áreas de negócio</p>
    <swiper-container
      fmSwiper
      direction="vertical"
      loop="true"
      autoplay="true"
      init="false"
      class="h-24 ml-0"
    >
      <swiper-slide id="container" *ngFor="let slider of sliders">
        <p class="setor mt-2">{{ slider }}</p>
      </swiper-slide>
    </swiper-container>
  </div>

  <p
    data-aos="zoom-in-up"
    data-aos-duration="1000"
    data-aos-anchor-placement="top-center"
    class="sobreText"
  >
    Na WMG - Retail Solutions, estamos comprometidos em disponibilizar produtos
    de alta qualidade, com designs inovadores e soluções completas para as mais
    diversas necessidades de negócio dos nossos clientes.
    <strong
      >A nossa equipa é experiente e está pronta para ajudá-lo a encontrar as
      melhores soluções para o seu negócio. </strong
    ><br /><br />
    Entre em contato conosco e descubra como podemos ajudá-lo a instigar o seu
    sucesso!
  </p>
</div>

import { Component, OnInit } from '@angular/core';

import * as AOS from 'aos';

@Component({
  selector: 'vex-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit{

  ngOnInit() {
    AOS.init();
    
  }

}

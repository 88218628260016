import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "vex-escritorios",
  templateUrl: "./escritorios.component.html",
  styleUrls: ["./escritorios.component.scss"],
})
export class EscritoriosComponent implements OnInit {
  constructor(private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.setPageTitle(
      "WMG Retail Group - Clippy: Material de Escritório"
    );
    this.seoService.addMetaTags({
      description:
        "Encontre tudo o que precisa para a escola e escritório na Clippy, a loja online de papelaria. Cadernos, material escolar e muito mais à sua disposição. Descubra já!",
      image_meta_title: "Clippy Material de Escritório",
    });

    AOS.init();
  }
}

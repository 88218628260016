<div class="initSpace"></div>
<div class="space"></div>
<h3>soluções para</h3>

<h2 class="pt-4">armazenamento</h2>

<div class="overflow-hidden">
  <img
    data-aos="zoom-out-up"
    data-aos-duration="1000"
    id="armazenamento_1"
    src="/assets/img/webp/armazenamento_1.webp"
  />
  <img
    data-aos="zoom-out-up"
    data-aos-duration="1000"
    id="armazenamento_mob"
    src="/assets/img/webp/armazenamento_mob.webp"
  />
</div>

<div class="">
  <p data-aos="zoom-in-up" data-aos-duration="1000" class="sobreText">
    A Racks® apresenta soluções para armazenagem, com estantes metálicas para
    armazéns e componentes que permitem otimizar espaço de armazenamento e
    organizar eficientemente os produtos. as nossa soluções são ricas em
    variedade de tamanhos e capacidades, ideais para armazéns, depósitos e
    espaços de estoque.

    <br /><br />

    <a class="link" href="/">voltar</a> <strong> |</strong
    ><a class="link" href="https://racks.pt/"> visitar website </a>
  </p>
</div>
<div class="flex flex-row items-center overflow-hidden">
  <img
    id="armazenamento_2"
    data-aos="zoom-in-right"
    data-aos-duration="1000"
    src="/assets/img/webp/armazenamento_2.webp"
  />
  <div
    data-aos="zoom-in-left"
    data-aos-duration="1000"
    class="w-full flex flex-cols justify-center items-center"
  >
    <a class="link" href="https://racks.pt/">
      <svg
        class="logo"
        width="40vw"
        viewBox="0 0 248 113"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_10_54)">
          <path d="M43.3022 0H0V91.743H43.3022V0Z" fill="white" />
          <path d="M89.7803 0H46.478V91.743H89.7803V0Z" fill="white" />
          <path d="M136.258 0H92.9561V91.743H136.258V0Z" fill="white" />
          <path d="M182.736 0H139.434V91.743H182.736V0Z" fill="white" />
          <path d="M229.207 0H185.905V91.743H229.207V0Z" fill="white" />
          <path
            d="M26.6118 30.599H8.05151V67.7042H15.4772V52.8606H21.7824L27.825 58.9572V67.7042H35.2429V57.4195C35.2429 56.415 34.872 55.5109 34.1843 54.7691L31.0549 51.6397C33.5971 50.102 35.2429 47.3435 35.2429 44.214V39.2301C35.2429 36.8424 34.3929 34.8334 32.7007 33.1335C31.0626 31.4412 28.9917 30.599 26.6118 30.599ZM27.825 44.2217C27.825 44.8631 27.2918 45.4426 26.6041 45.4426H15.4772V38.0247H26.6118C27.2995 38.0247 27.8327 38.6042 27.8327 39.2455V44.2217H27.825Z"
            fill="#222222"
          />
          <path
            d="M73.9322 32.6622C73.2986 31.3872 72.0236 30.5913 70.5941 30.5913H65.6643C64.2348 30.5913 62.9598 31.3872 62.3262 32.6622L54.9082 47.5058C54.6455 47.9848 54.5374 48.5644 54.5374 49.1516V67.7042H61.963V57.7904H74.3108V67.7042H81.7287V49.1516C81.7287 48.5644 81.6206 47.9848 81.3579 47.5058L73.9322 32.6622ZM74.3031 50.3725H61.9553V50.0557L67.9437 38.0247H68.3146L74.3031 50.0557V50.3725Z"
            fill="#222222"
          />
          <path
            d="M119.56 30.599H109.646C104.925 30.599 101.008 34.5243 101.008 39.2378V59.0654C101.008 61.453 101.858 63.462 103.55 65.162C105.25 66.8619 107.259 67.7042 109.646 67.7042H119.56C121.948 67.7042 124.011 66.8542 125.657 65.162C127.357 63.462 128.199 61.453 128.199 59.0654V55.3564H120.773V59.0654C120.773 59.7531 120.248 60.2862 119.552 60.2862H109.646C109.013 60.2862 108.426 59.7067 108.426 59.0654V39.2378C108.426 38.6042 109.005 38.0169 109.646 38.0169H119.56C120.248 38.0169 120.781 38.6042 120.781 39.2378V42.9468H128.207V39.2378C128.207 36.8502 127.357 34.8334 125.665 33.1412C124.011 31.4412 121.948 30.599 119.56 30.599Z"
            fill="#222222"
          />
          <path
            d="M167.259 40.1882L162.012 45.4349H154.911V30.599H147.486V67.7042H154.911V52.8606H162.012L167.259 58.1072V67.7042H174.677V56.5695C174.677 55.565 174.306 54.661 173.618 53.9732L168.797 49.1516L173.618 44.3299C174.314 43.6422 174.677 42.7382 174.677 41.7336V30.599H167.259V40.1882Z"
            fill="#222222"
          />
          <path
            d="M221.155 40.8837V39.2378C221.155 36.8502 220.305 34.8411 218.613 33.1412C216.967 31.4412 214.904 30.599 212.516 30.599H202.603C200.215 30.599 198.206 31.449 196.506 33.1412C194.806 34.8411 193.964 36.8502 193.964 39.2378V41.7259C193.964 42.6222 194.172 43.5263 194.543 44.5386C195.339 46.393 196.614 47.8766 198.469 48.8811L198.577 48.9352L213.104 55.9282C213.475 56.1445 213.683 56.4073 213.737 56.7241V59.0576C213.737 59.7453 213.212 60.2785 212.516 60.2785H202.603C201.969 60.2785 201.382 59.699 201.382 59.0576V57.4118H193.964V59.0576C193.964 63.7788 197.889 67.6964 202.603 67.6964H212.516C214.904 67.6964 216.967 66.8465 218.613 65.1543C220.313 63.4543 221.155 61.4453 221.155 59.0576V56.5695C221.155 55.6655 220.939 54.7691 220.568 53.7569C219.772 51.9024 218.505 50.4188 216.65 49.4066L216.542 49.3525L202.015 42.3518C201.645 42.1432 201.428 41.8805 201.382 41.5559V39.2224C201.382 38.5887 201.969 38.0015 202.603 38.0015H212.516C213.204 38.0015 213.737 38.5887 213.737 39.2224V40.8682H221.155V40.8837Z"
            fill="#222222"
          />
          <path
            d="M4.43531 103.689C4.43531 102.947 4.02578 102.429 2.58855 102.429C1.15133 102.429 0.741794 102.947 0.741794 103.689V104.145C0.741794 104.778 1.18996 105.072 1.52222 105.196L3.90215 106.1C4.52031 106.332 5.13847 106.803 5.13847 107.645V108.735C5.13847 109.701 4.54349 110.481 2.5731 110.481C0.602708 110.481 0 109.701 0 108.735V108.093C0 107.985 0.0463621 107.931 0.146813 107.931H0.509984C0.610435 107.931 0.656797 107.977 0.656797 108.093V108.665C0.656797 109.407 1.11269 109.932 2.56537 109.932C4.02578 109.932 4.49713 109.415 4.49713 108.665V107.784C4.49713 107.112 3.95624 106.818 3.46943 106.633L1.16678 105.744C0.672251 105.559 0.100451 105.064 0.100451 104.284V103.612C0.100451 102.646 0.64907 101.865 2.58855 101.865C4.52031 101.865 5.07665 102.646 5.07665 103.612V104.222C5.07665 104.323 5.03802 104.369 4.92984 104.369H4.56667C4.46622 104.369 4.42758 104.315 4.42758 104.222V103.689H4.43531Z"
            fill="white"
          />
          <path
            d="M10.6401 103.612C10.6401 102.646 11.2351 101.865 13.2132 101.865C15.2068 101.865 15.8095 102.646 15.8095 103.612V108.735C15.8095 109.701 15.1991 110.481 13.2132 110.481C11.2351 110.481 10.6401 109.701 10.6401 108.735V103.612ZM15.145 103.712C15.145 102.955 14.6582 102.437 13.2132 102.437C11.776 102.437 11.2969 102.955 11.2969 103.712V108.642C11.2969 109.399 11.7837 109.917 13.2132 109.917C14.6659 109.917 15.145 109.399 15.145 108.642V103.712Z"
            fill="white"
          />
          <path
            d="M22.2615 109.816H25.9241C26.0246 109.816 26.071 109.855 26.071 109.963V110.234C26.071 110.342 26.0246 110.381 25.9241 110.381H21.7284C21.6511 110.381 21.6047 110.334 21.6047 110.234V102.113C21.6047 102.012 21.6588 101.966 21.7516 101.966H22.1224C22.2229 101.966 22.2615 102.012 22.2615 102.113V109.816Z"
            fill="white"
          />
          <path
            d="M31.8663 108.642C31.8663 109.399 32.3531 109.917 33.798 109.917C35.2507 109.917 35.7298 109.399 35.7298 108.642V102.12C35.7298 102.02 35.7761 101.974 35.8766 101.974H36.2475C36.3479 101.974 36.3866 102.02 36.3866 102.12V108.742C36.3866 109.708 35.7916 110.489 33.798 110.489C31.8199 110.489 31.2095 109.708 31.2095 108.742V102.12C31.2095 102.02 31.2558 101.974 31.3563 101.974H31.7272C31.8276 101.974 31.8663 102.02 31.8663 102.12V108.642Z"
            fill="white"
          />
          <path
            d="M44.8941 111.053C45.3809 111.053 45.9064 111.292 45.9064 112.019C45.9064 112.753 45.3732 113 44.8941 113H43.4337C43.3333 113 43.2869 112.961 43.2869 112.853V112.714C43.2869 112.614 43.3333 112.567 43.4337 112.567H44.8246C45.0718 112.567 45.4196 112.482 45.4196 112.026C45.4196 111.57 45.0718 111.493 44.8246 111.493H44.0982C43.9128 111.493 43.8742 111.408 43.8973 111.308L44.1369 110.466C42.5528 110.357 42.1201 109.623 42.1201 108.742V103.619C42.1201 102.653 42.6533 101.873 44.6469 101.873C46.6404 101.873 47.1736 102.653 47.1736 103.619V104.261C47.1736 104.377 47.135 104.423 47.0345 104.423H46.6791C46.5786 104.423 46.5322 104.377 46.5322 104.261V103.72C46.5322 102.963 46.0995 102.445 44.6469 102.445C43.2019 102.445 42.7769 102.963 42.7769 103.72V108.65C42.7769 109.407 43.2019 109.925 44.6469 109.925C46.0995 109.925 46.5322 109.407 46.5322 108.65V107.946C46.5322 107.846 46.5786 107.807 46.6791 107.807H47.0345C47.135 107.807 47.1736 107.846 47.1736 107.946V108.75C47.1736 109.716 46.6404 110.496 44.6469 110.496H44.585L44.4228 111.068H44.8941V111.053Z"
            fill="white"
          />
          <path
            d="M52.4434 103.612C52.4434 102.646 53.0383 101.865 55.0165 101.865C57.01 101.865 57.6127 102.646 57.6127 103.612V108.735C57.6127 109.701 57.0023 110.481 55.0165 110.481C53.0383 110.481 52.4434 109.701 52.4434 108.735V103.612ZM56.9482 103.712C56.9482 102.955 56.4691 102.437 55.0165 102.437C53.5792 102.437 53.1002 102.955 53.1002 103.712V108.642C53.1002 109.399 53.587 109.917 55.0165 109.917C56.4691 109.917 56.9482 109.399 56.9482 108.642V103.712ZM53.8265 100.807V100.915C53.8265 101.015 53.7647 101.054 53.6642 101.054H53.4556C53.3551 101.054 53.332 100.992 53.332 100.884L53.3474 100.637C53.3861 99.8795 53.7183 99.6323 54.1742 99.6323C54.3828 99.6323 54.6224 99.7096 54.8156 99.8641L55.3874 100.32C55.5264 100.428 55.6964 100.529 55.8355 100.529C56.1446 100.529 56.2219 100.39 56.2219 99.8641V99.7559C56.2219 99.6555 56.2837 99.6168 56.3841 99.6168H56.585C56.6855 99.6168 56.7241 99.6786 56.7241 99.7791L56.7087 100.026C56.67 100.784 56.3378 101.039 55.8664 101.039C55.6964 101.039 55.4492 100.961 55.2251 100.791L54.6687 100.343C54.5219 100.235 54.3597 100.135 54.2206 100.135C53.8883 100.135 53.8265 100.274 53.8265 100.807Z"
            fill="white"
          />
          <path
            d="M63.5392 110.388C63.4542 110.388 63.4001 110.342 63.4001 110.241V102.12C63.4001 102.02 63.4465 101.974 63.547 101.974H67.7659C67.8664 101.974 67.9127 102.012 67.9127 102.12V102.391C67.9127 102.499 67.8664 102.538 67.7659 102.538H64.0569V105.682H67.5496C67.65 105.682 67.6964 105.721 67.6964 105.829V106.092C67.6964 106.192 67.65 106.239 67.5496 106.239H64.0569V109.816H67.9205C68.0209 109.816 68.0673 109.855 68.0673 109.963V110.234C68.0673 110.342 68.0209 110.381 67.9205 110.381H63.5392V110.388Z"
            fill="white"
          />
          <path
            d="M77.6721 103.689C77.6721 102.947 77.2626 102.429 75.8254 102.429C74.3881 102.429 73.9786 102.947 73.9786 103.689V104.145C73.9786 104.778 74.4268 105.072 74.759 105.196L77.139 106.1C77.7571 106.332 78.3753 106.803 78.3753 107.645V108.735C78.3753 109.701 77.7803 110.481 75.8099 110.481C73.8395 110.481 73.2368 109.701 73.2368 108.735V108.093C73.2368 107.985 73.2832 107.931 73.3836 107.931H73.7468C73.8473 107.931 73.8936 107.977 73.8936 108.093V108.665C73.8936 109.407 74.3495 109.932 75.8022 109.932C77.2626 109.932 77.7339 109.415 77.7339 108.665V107.784C77.7339 107.112 77.1931 106.818 76.7062 106.633L74.4036 105.744C73.9091 105.559 73.3373 105.064 73.3373 104.284V103.612C73.3373 102.646 73.8859 101.865 75.8254 101.865C77.7571 101.865 78.3135 102.646 78.3135 103.612V104.222C78.3135 104.323 78.2748 104.369 78.1667 104.369H77.8035C77.703 104.369 77.6644 104.315 77.6644 104.222V103.689H77.6721Z"
            fill="white"
          />
          <path
            d="M90.5839 110.388C90.4835 110.388 90.4448 110.342 90.4448 110.241V102.12C90.4448 102.02 90.4912 101.974 90.5839 101.974H93.0334C95.0115 101.974 95.6219 102.754 95.6219 103.72V108.65C95.6219 109.616 95.0115 110.396 93.0334 110.396H90.5839V110.388ZM91.1016 102.538V109.816H93.0334C94.4861 109.816 94.9651 109.299 94.9651 108.541V103.813C94.9651 103.055 94.4783 102.538 93.0334 102.538H91.1016Z"
            fill="white"
          />
          <path
            d="M101.564 110.388C101.479 110.388 101.425 110.342 101.425 110.241V102.12C101.425 102.02 101.471 101.974 101.572 101.974H105.791C105.891 101.974 105.937 102.012 105.937 102.12V102.391C105.937 102.499 105.891 102.538 105.791 102.538H102.074V105.682H105.566C105.667 105.682 105.713 105.721 105.713 105.829V106.092C105.713 106.192 105.667 106.239 105.566 106.239H102.074V109.816H105.937C106.038 109.816 106.084 109.855 106.084 109.963V110.234C106.084 110.342 106.038 110.381 105.937 110.381H101.564V110.388Z"
            fill="white"
          />
          <path
            d="M120.155 101.966C120.256 101.966 120.317 102.012 120.341 102.113L122.852 110.234C122.891 110.334 122.844 110.381 122.744 110.381H122.357C122.257 110.381 122.211 110.334 122.18 110.234L121.422 107.831H118.44L117.706 110.234C117.683 110.334 117.628 110.381 117.536 110.381H117.173C117.072 110.381 117.026 110.334 117.064 110.234L119.576 102.113C119.599 102.012 119.661 101.966 119.761 101.966H120.155ZM120.256 103.983C120.132 103.573 119.97 102.793 119.947 102.669C119.931 102.754 119.784 103.55 119.661 103.959L118.61 107.305H121.283L120.256 103.983Z"
            fill="white"
          />
          <path
            d="M128.578 106.896V110.241C128.578 110.342 128.539 110.388 128.439 110.388H128.068C127.967 110.388 127.921 110.342 127.921 110.241V102.12C127.921 102.02 127.967 101.974 128.06 101.974H130.479C132.472 101.974 133.067 102.754 133.067 103.72V105.157C133.067 105.945 132.681 106.61 131.483 106.826L133.175 110.241C133.214 110.326 133.152 110.388 133.036 110.388H132.665C132.542 110.388 132.518 110.326 132.48 110.241L130.849 106.888C130.726 106.903 130.602 106.903 130.479 106.903H128.578V106.896ZM128.578 102.538V106.339H130.471C131.916 106.339 132.403 105.822 132.403 105.064V103.813C132.403 103.055 131.916 102.538 130.471 102.538H128.578Z"
            fill="white"
          />
          <path
            d="M145.291 110.241C145.291 110.342 145.252 110.388 145.152 110.388H144.789C144.688 110.388 144.642 110.342 144.642 110.241V103.395L144.418 104.044L142.386 108.959C142.347 109.059 142.285 109.106 142.185 109.106H141.837C141.737 109.106 141.69 109.059 141.652 108.959L139.55 103.959C139.465 103.789 139.38 103.473 139.365 103.411V110.249C139.365 110.35 139.326 110.396 139.225 110.396H138.893C138.793 110.396 138.746 110.35 138.746 110.249V102.128C138.746 102.028 138.793 101.981 138.893 101.981H139.225C139.303 101.981 139.334 102.004 139.365 102.066L141.814 107.846C141.922 108.109 142.015 108.426 142.015 108.426C142.015 108.426 142.139 108.07 142.223 107.854L144.65 102.051C144.673 101.989 144.712 101.974 144.789 101.974H145.152C145.252 101.974 145.291 102.02 145.291 102.12V110.241Z"
            fill="white"
          />
          <path
            d="M153.443 101.966C153.544 101.966 153.606 102.012 153.629 102.113L156.14 110.234C156.179 110.334 156.132 110.381 156.032 110.381H155.645C155.545 110.381 155.499 110.334 155.468 110.234L154.71 107.831H151.728L150.994 110.234C150.971 110.334 150.916 110.381 150.824 110.381H150.461C150.36 110.381 150.314 110.334 150.352 110.234L152.864 102.113C152.887 102.012 152.949 101.966 153.049 101.966H153.443ZM153.544 103.983C153.42 103.573 153.258 102.793 153.235 102.669C153.219 102.754 153.072 103.55 152.949 103.959L151.898 107.305H154.571L153.544 103.983Z"
            fill="white"
          />
          <path
            d="M165.381 101.966C165.482 101.966 165.528 102.004 165.528 102.113V102.383L165.505 102.491L161.503 109.809H165.567C165.667 109.809 165.714 109.847 165.714 109.956V110.226C165.714 110.342 165.667 110.373 165.567 110.373H160.853C160.753 110.373 160.714 110.334 160.714 110.226V109.956C160.714 109.878 160.73 109.847 160.753 109.793L164.717 102.522H161.078C160.977 102.522 160.931 102.484 160.931 102.375V102.105C160.931 101.997 160.977 101.958 161.078 101.958H165.381V101.966Z"
            fill="white"
          />
          <path
            d="M171.146 110.388C171.061 110.388 171.007 110.342 171.007 110.241V102.12C171.007 102.02 171.053 101.974 171.154 101.974H175.373C175.473 101.974 175.519 102.012 175.519 102.12V102.391C175.519 102.499 175.473 102.538 175.373 102.538H171.656V105.682H175.149C175.249 105.682 175.295 105.721 175.295 105.829V106.092C175.295 106.192 175.249 106.239 175.149 106.239H171.656V109.816H175.519C175.62 109.816 175.666 109.855 175.666 109.963V110.234C175.666 110.342 175.62 110.381 175.519 110.381H171.146V110.388Z"
            fill="white"
          />
          <path
            d="M186.26 110.241C186.26 110.342 186.221 110.388 186.121 110.388H185.788C185.711 110.388 185.673 110.365 185.649 110.311L182.11 104.006C181.925 103.712 181.724 103.303 181.716 103.264V110.234C181.716 110.334 181.678 110.381 181.577 110.381H181.245C181.145 110.381 181.098 110.334 181.098 110.234V102.113C181.098 102.012 181.145 101.966 181.245 101.966H181.577L181.701 102.028L185.278 108.379C185.394 108.58 185.642 109.082 185.642 109.082V102.113C185.642 102.012 185.688 101.966 185.788 101.966H186.121C186.221 101.966 186.26 102.012 186.26 102.113V110.241Z"
            fill="white"
          />
          <path
            d="M194.412 101.966C194.512 101.966 194.574 102.012 194.597 102.113L197.109 110.234C197.147 110.334 197.101 110.381 197.001 110.381H196.614C196.514 110.381 196.467 110.334 196.436 110.234L195.679 107.831H192.697L191.963 110.234C191.939 110.334 191.885 110.381 191.793 110.381H191.429C191.329 110.381 191.283 110.334 191.321 110.234L193.832 102.113C193.856 102.012 193.917 101.966 194.018 101.966H194.412ZM194.512 103.983C194.389 103.573 194.227 102.793 194.203 102.669C194.188 102.754 194.041 103.55 193.917 103.959L192.867 107.305H195.54L194.512 103.983Z"
            fill="white"
          />
          <path
            d="M206.821 106.44C206.93 106.44 206.968 106.486 206.968 106.587V108.742C206.968 109.708 206.435 110.489 204.442 110.489C202.448 110.489 201.915 109.708 201.915 108.742V103.619C201.915 102.653 202.448 101.873 204.442 101.873C206.435 101.873 206.968 102.653 206.968 103.619V104.238C206.968 104.338 206.93 104.384 206.829 104.384H206.466C206.366 104.384 206.319 104.33 206.319 104.238V103.72C206.319 102.963 205.886 102.445 204.434 102.445C202.989 102.445 202.564 102.963 202.564 103.72V108.65C202.564 109.407 202.981 109.925 204.434 109.925C205.871 109.925 206.319 109.415 206.319 108.673V107.012H204.55C204.449 107.012 204.403 106.973 204.403 106.865V106.594C204.403 106.494 204.449 106.447 204.55 106.447H206.821V106.44Z"
            fill="white"
          />
          <path
            d="M212.725 110.388C212.64 110.388 212.586 110.342 212.586 110.241V102.12C212.586 102.02 212.632 101.974 212.733 101.974H216.952C217.052 101.974 217.099 102.012 217.099 102.12V102.391C217.099 102.499 217.052 102.538 216.952 102.538H213.235V105.682H216.728C216.828 105.682 216.874 105.721 216.874 105.829V106.092C216.874 106.192 216.828 106.239 216.728 106.239H213.235V109.816H217.099C217.199 109.816 217.245 109.855 217.245 109.963V110.234C217.245 110.342 217.199 110.381 217.099 110.381H212.725V110.388Z"
            fill="white"
          />
          <path
            d="M229.207 110.241C229.207 110.342 229.168 110.388 229.068 110.388H228.704C228.604 110.388 228.558 110.342 228.558 110.241V103.395L228.333 104.044L226.301 108.959C226.263 109.059 226.201 109.106 226.1 109.106H225.753C225.652 109.106 225.606 109.059 225.567 108.959L223.465 103.959C223.38 103.789 223.295 103.473 223.28 103.411V110.249C223.28 110.35 223.241 110.396 223.141 110.396H222.809C222.708 110.396 222.662 110.35 222.662 110.249V102.128C222.662 102.028 222.708 101.981 222.809 101.981H223.141C223.218 101.981 223.257 102.004 223.28 102.066L225.729 107.846C225.838 108.109 225.93 108.426 225.93 108.426C225.93 108.426 226.054 108.07 226.139 107.854L228.565 102.051C228.588 101.989 228.627 101.974 228.704 101.974H229.068C229.168 101.974 229.207 102.02 229.207 102.12V110.241Z"
            fill="white"
          />
          <path
            d="M237.737 4.6207C237.737 4.28844 237.992 4.03345 238.325 4.03345H240.704C241.539 4.03345 242.204 4.28071 242.629 4.69797C242.984 5.06114 243.185 5.55567 243.185 6.1352V6.15838C243.185 7.22471 242.567 7.87378 241.67 8.15195L242.945 9.75917C243.061 9.89826 243.138 10.0296 243.138 10.2073C243.138 10.5319 242.86 10.7482 242.574 10.7482C242.312 10.7482 242.134 10.6246 242.003 10.4469L240.38 8.37604H238.904V10.1687C238.904 10.4932 238.649 10.7482 238.325 10.7482C237.992 10.7482 237.737 10.4932 237.737 10.1687V4.6207ZM240.619 7.33289C241.454 7.33289 241.987 6.89245 241.987 6.2202V6.19701C241.987 5.48613 241.469 5.09205 240.604 5.09205H238.896V7.33289H240.619Z"
            fill="white"
          />
          <path
            d="M240.457 14.0245C236.802 14.0245 233.82 11.0496 233.82 7.38703C233.82 3.73214 236.795 0.749512 240.457 0.749512C244.112 0.749512 247.095 3.72442 247.095 7.38703C247.095 11.0419 244.12 14.0245 240.457 14.0245ZM240.457 1.69221C237.32 1.69221 234.763 4.24985 234.763 7.38703C234.763 10.5242 237.32 13.0818 240.457 13.0818C243.595 13.0818 246.152 10.5319 246.152 7.38703C246.152 4.24985 243.595 1.69221 240.457 1.69221Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_10_54">
            <rect width="247.095" height="113" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  </div>
</div>

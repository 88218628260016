<header class="z-20">
  <a routerLink="" (click)="updateLink('company')">
    <svg  class="h-[100%]  p-4 ml-5" width="133" height="41" viewBox="0 0 133 41" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5094 0.240295C25.9497 0.240295 24.5585 1.22488 24.0474 2.69117L15.6215 25.9374L7.14817 2.67264C6.5817 1.19047 5.22218 0.245589 3.68086 0.245589C3.2672 0.245589 2.85355 0.314404 2.45834 0.454681C1.50193 0.825223 0.769473 1.49749 0.35582 2.36827C-0.0736426 3.2708 -0.113164 4.28186 0.242526 5.21615L12.1673 37.9827C12.6732 39.4331 14.0644 40.4177 15.6241 40.4177C17.1365 40.4177 18.4854 39.4755 19.0677 38.0198L27.5147 14.7179L35.988 37.9827C36.4939 39.4305 37.885 40.4177 39.4448 40.4177C41.0045 40.4177 42.3957 39.4331 42.9068 37.9668L54.7869 5.19498C55.1373 4.28186 55.0978 3.2708 54.6657 2.36827C54.252 1.49749 53.5195 0.825223 52.6027 0.470561C52.1653 0.317051 51.7543 0.248236 51.338 0.248236C49.7967 0.248236 48.4371 1.19312 47.8786 2.65412L39.4421 25.9374L30.9688 2.67264C30.463 1.22488 29.0718 0.240295 27.5094 0.240295Z"
        fill="white"/>
      <path
        d="M75.2666 40.4204C76.8263 40.4204 78.2175 39.4358 78.7286 37.9695L87.1545 14.7232L95.6278 37.988C96.1943 39.4702 97.5538 40.4151 99.0951 40.4151C99.5088 40.4151 99.9224 40.3463 100.318 40.206C101.274 39.8381 102.006 39.1632 102.42 38.2924C102.85 37.3899 102.889 36.3788 102.533 35.4445L90.6086 2.67531C90.1028 1.2249 88.7116 0.240318 87.1518 0.240318C85.6395 0.240318 84.2905 1.18255 83.7082 2.63826L75.2639 25.9374L66.7906 2.67267C66.2847 1.2249 64.8936 0.237671 63.3338 0.237671C61.7741 0.237671 60.3829 1.22226 59.8718 2.68855L47.9891 35.4657C47.6387 36.3788 47.6782 37.3899 48.1103 38.2924C48.524 39.1632 49.2564 39.8354 50.1733 40.1901C50.6107 40.3436 51.0217 40.4124 51.438 40.4124C52.9793 40.4124 54.3388 39.4675 54.8974 38.0065L63.3365 14.7232L71.8098 37.988C72.313 39.4331 73.7042 40.4204 75.2666 40.4204Z"
        fill="white" />
      <path
        d="M106.691 11.0813C107.922 9.84531 109.402 8.85279 110.983 8.20963L110.996 8.20434C112.64 7.48178 114.416 7.11653 116.276 7.11653C119.043 7.11653 121.659 7.95819 123.854 9.55682C125.44 10.6579 127.658 10.2741 128.807 8.68869C129.358 7.89203 129.574 6.94185 129.416 6.0102C129.255 5.07855 128.736 4.26071 127.951 3.71548C122.228 -0.336665 114.869 -1.12804 108.261 1.60604C105.708 2.70443 103.493 4.18925 101.675 6.01814C100.197 7.46061 98.9371 9.15716 97.9307 11.0522L97.6777 11.526L102.22 23.8783L102.797 18.9342C102.942 17.7035 103.258 16.4939 103.735 15.3532C104.399 13.8075 105.369 12.41 106.691 11.0813Z"
        fill="white" />
      <path
        d="M121.158 24.0477H132.132V16.9307H121.266C119.277 16.9307 117.72 18.4949 117.72 20.4932C117.717 22.457 119.222 23.9921 121.158 24.0477Z"
        fill="#2ED9C3" />
      <path
        d="M125.039 26.0063V30.4343C124.073 31.3289 122.926 32.0938 121.625 32.7025C119.952 33.478 118.118 33.875 116.181 33.875C112.751 33.875 109.46 32.5384 106.915 30.1114L103.211 26.578L107.853 39.1976L108.319 39.3988C110.786 40.4601 113.431 41 116.181 41C119.14 41 121.954 40.3913 124.544 39.1897C127.008 38.0754 129.25 36.4318 131.218 34.2985C131.8 33.6395 132.132 32.7819 132.132 31.9403V26.0063H125.039Z"
        fill="white" />
    </svg>
  </a>

  <ul id="menu_comp" class="w-full flex flex-row justify-end flex-wrap-reverse">
    <li class="main_option" id="company">
      <a routerLink="/" (click)="updateLink('company')" >empresa</a>
    </li>
    <li class="main_option" id="services"><button (click)="openServiceOption()">áreas de negócio</button></li>
    <li class="main_option" id="contacts"><a routerLink="contactos" (click)="updateLink('contacts')">contactos</a></li>
    <li class="main_option" id="area-reservada"><a href="https://my.wmg.com.pt/" target="_blank">área reservada</a></li>
  </ul>

    <ul *ngIf="s_option" id="servicesOptions">
    <li class="li_opt"> <a routerLink="armazenamento" id="services_armazenamento" class="s_opt"  routerLinkActive="s_active"  (click)="updateLink('services')">armazenamento</a> </li>
    <li class="li_opt"> <a routerLink="hotelaria" id="services_hotelaria" class="s_opt"  routerLinkActive="s_active" (click)="updateLink('services')">hotelaria</a> </li>
    <li class="li_opt"> <a routerLink="expositores" id="services_expositores" class="s_opt"  routerLinkActive="s_active" (click)="updateLink('services')">expositores</a> </li>
    <li class="li_opt"> <a routerLink="escritorios" id="services_escritorios" class="s_opt"  routerLinkActive="s_active"  (click)="updateLink('services')">escritórios</a> </li>
    <li class="li_opt"> <a routerLink="decoracao" id="services_decoracao" class="s_opt" routerLinkActive="s_active"  (click)="updateLink('services')">decoração</a> </li>
  </ul>

  <a (click)="showMenuTelm()"><svg id="menu_telm" width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_251_253" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="45">
    <rect id="menu_telm" width="45" height="45" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_251_253)">
    <path id="color" d="M5.36731 34.1756V29.91H39.6329V34.1756H5.36731ZM5.36731 24.6325V20.3669H39.6329V24.6325H5.36731ZM5.36731 15.0894V10.8237H39.6329V15.0894H5.36731Z" fill= "white"/>
    </g>
    </svg></a>
    
  


<ul *ngIf="menu_telm" id="menu_op_telm">
  <li class="t_main"><a routerLink="/" [routerLinkActiveOptions]={exact:true} routerLinkActive="t_main_act" (click)="closeMenu()">empresa</a></li>
  <li class="t_main" id="t_services"><a (click)="showOptionService()">áreas de negócio</a></li>
  <li class="t_sub" *ngIf="ser_selected"><a routerLink="armazenamento" routerLinkActive="t_act" (click)="closeMenu()">armazenamento</a></li>
  <li class="t_sub" *ngIf="ser_selected"><a routerLink="expositores" routerLinkActive="t_act" (click)="closeMenu()">expositores</a></li>
  <li class="t_sub" *ngIf="ser_selected"><a routerLink="hotelaria" routerLinkActive="t_act" (click)="closeMenu()">hotelaria</a></li>
  <li class="t_sub" *ngIf="ser_selected"><a routerLink="escritorios" routerLinkActive="t_act" (click)="closeMenu()">escritórios</a></li>
  <li class="t_sub" *ngIf="ser_selected"><a routerLink="decoracao" routerLinkActive="t_act" (click)="closeMenu()">decoração</a></li>
  <li class="t_main"><a routerLink="contactos" routerLinkActive="t_main_act" (click)="closeMenu()">contactos</a></li>
  <li class="t_main"id="t_area"><a href="https://my.wmg.com.pt/" target="_blank">área reservada</a></li>
</ul>
</header>
<div class="initSpace"></div>
<div class="space"></div>
<div data-aos="zoom-in-up" data-aos-duration="1000" data-aos-anchor-placement="top-center" class="divText">
  <h2 class="pt-4">Política de Privacidade</h2>
  <p class="sobreText">

    <strong>Menções Legais</strong>
    <br><br>
    WMG – Online Retail Business Group <br>
    Rua dos Portais nº 85, <br>
    Eira da Pedra 2495-300 Fátima <br>

    Para qualquer informação adicional sobre algum texto, imagem ou funcionalidade do website, pedimos que nos envie um
    email para info@wmg.com.pt. <br> <br>

    <strong>Proteção de dados</strong>
    <br><br>

    Todos os dados presentes nos diversos sistemas e bases de dados da marca Wmg – Online Retail Business, Lda foram
    recolhidos através de relações contratuais e intervenções profissionais com os clientes Wmg – Online Retail
    Business, Lda. A sua utilização é simplesmente para os fins referidos anteriormente e para o envio de newsletters
    informativas e promocionais.
    <br><br>
    Os seus dados poderão ser eliminados a qualquer momento, desde que expresse essa vontade de forma escrita. A Wmg –
    Online Retail Business, Lda rege-se pelo cumprimento da lei em vigor, ou seja, a Lei 67/98, de 26 de outubro,
    retificada pela Lei n.º 103/2015, de 24/08.
    <br><br>
    Após o dia 25 de maio de 2018 passa a ser aplicado o Regulamento Geral de Proteção de Dados Pessoais (RPGD) n.º
    2016/679 do Parlamento Europeu e do Conselho Europeu, de 27 de Abril de 2016, que estabelece e normaliza o
    tratamento e a proteção dos dados pessoais.
    <br><br>
    Garantimos desde já, que os seus dados pessoais não serão transferidos para uma empresa terceira e muito menos
    comercializados. Por outro lado, não podemos garantir o sigilo das
    suas mensagens que são enviadas através do email porque são essenciais para o normal funcionamento da empresa.
    <br><br>
    A Wmg – Online Retail Business, Lda irá conservar os seus dados pessoais pelo período necessário à prestação de
    serviços, fatura e cumprimento das obrigações legais e para efeitos de marketing “relacionado com a nossa área de
    atividade”. As bases de dados estão salvaguardas e protegidas de forma a não existir quaisquer tipos de fuga ou
    utilização indevida. Neste sentido estão salvaguardas de acordo com o Código da Proteção Intelectualidade e
    legislação em vigor.
    <br><br>

    <strong>Quais os dados utilizados?</strong>
    <br><br>
    Atualmente, a Wmg – Online Retail Business, Lda utiliza os dados pessoais essenciais para que utilizador elabore um
    pedido de informação sobre os produtos que comercializamos, tais como:
    <br><br>
    Nome: essencial para identificar o pedido e o cliente.<br>
    Morada: essencial para o envio da mercadoria<br>
    Email: essencial para contactar o cliente e responder ao pedido realizado.<br>
    Contacto Telefónico: essencial para informar ou pedir informações específicas sobre o pedido.<br><br>

    <strong>Como protegemos os seus dados?</strong>  <br><br>
    Os seus dados estão protegidos pela legislação referida anteriormente. Para além disso, estão guardados num espaço
    onde apenas o responsável da proteção de dados tem acesso ou
    determinadas pessoas mencionadas para o efeito. Pretendemos evitar a todo o custo a fuga total ou parcial dos dados
    pessoais dos seus clientes.
    <br><br>
    Nenhuma técnica adicional será realizada para adquirir os seus dados pessoais e informação extraordinária.
    Acrescentamos ainda que não utilizamos nenhuma técnica para alterar ou modificar os seus dados pessoais.
    <br><br>
    <strong>Gestão dos Dados Pessoais</strong>  
    <br><br>
    A recolha dos seus dados apenas será realizada com o seu consentimento. A utilização destes dados destina-se apenas
    à comunicação interempresarial e para envio de newsletters informativas e promocionais.

    <br><br>
    <strong>Direitos de Autor</strong>
    <br><br>
    A Wmg – Online Retail Business, Lda está protegida pela lei portuguesa e internacional sobre a proteção de autor,
    proteção de dados e proteção intelectualidade. Todos os conteúdos, imagens e vídeos foram concebidos internamente
    pela marca. Sendo essencial refletir que quaisquer alterações deste são condenadas pelo abrigo
    da lei em vigor.
    <br><br>
    Esta empresa reserva-se ao direito de não aceitar campanhas ou anúncios publicitários de empresas terceiros. Caso
    aconteça, iremos recorrer às ferramentas e meios necessários existentes para condenar as infrações.
    <br><br>
    A Wmg – Online Retail Business, Lda pode usufruir do direito de modificar quaisquer textos, imagens ou vídeos,
    sempre que considerar desejável ou oportuno.
    <br><br>
    <strong>Email Marketing</strong>
    <br><br>
    Ao realizar a inscrição na nossa newsletter, está a dar o seu consentimento de uma forma voluntária para a
    utilização dos seus dados para receber campanhas de marketing realizadas pela Wmg – Online Retail Business, Lda.
    Para cancelar esse mesmo recebimento, basta clicar no final da newsletter ou enviar um email para cancelar através
    de info@wmg.com.pt
    <br><br>
    <strong>Sistemas de Links</strong>
    <br><br>
    Todo o sistema de links internos e externos utilizados no nosso website são apenas com a finalidade de facilidade de
    melhorar a navegação e direcionar melhor o utilizador para o que necessita. Não podemos assumir quaisquer tipos de
    responsabilidade sobre os problemas resultantes dos links externos.
    <br><br>
    <strong>O que são cookies?</strong>
    <br><br>
    Os cookies são pequenos ficheiros de texto enviados para o seu computador ou dispositivo móvel quando visita
    websites. Os cookies ajudam os websites a armazenar e recuperar informação sobre os seus hábitos de navegação por
    forma a personalizar a navegação de acordo com as suas preferências.
    <br><br>
    <strong>Cookies Utilizados</strong>
    <br><br>
    No momento em que visite o nosso website contém cookies alojados no seu equipamento (computador, tablet ou
    telemóvel) de forma a melhor a forma de navegar e o funcionamento do nosso website. Não permitimos de qualquer forma
    cookies de empresas estrangeiras sem o nosso consentimento.
    <br>
    Atualmente, utilizamos nomeadamente os seguintes cookies:
    <br><br>
    <strong>Cookies funcionais</strong>
    Acreditar a boa ligação ao website.
    Supervisionar dados resultantes da ligação.
    Conservar a coerência gráfica do website.
    <br><br>
    <strong>Cookies publicitários</strong>
    <br><br>
    Apresentar e realçar anúncios promocionais.
    A aceitação ou a desativação dos cookies poderão ser realizadas a qualquer momento. Contudo é essencial ter o
    conhecimento que essa desativação irá criar alguns prolemas de navegação e coerência gráfica. Alertamos que os
    navegadores guardam os cookies e a Wmg – Online Retail Business, Lda não assume qualquer risco por esse facto.
    <br><br>
    Pode ver mais detalhes em Política de cookiesApresentar e realçar anúncios promocionais.
      A aceitação ou a desativação dos cookies poderão ser realizadas a qualquer momento. Contudo é essencial ter o
      conhecimento que essa desativação irá criar alguns prolemas de navegação e coerência gráfica. Alertamos que os
      navegadores guardam os cookies e a Wmg – Online Retail Business, Lda não assume qualquer risco por esse facto.
      <br><br>
      Pode ver mais detalhes em <a href="/politica-cookies">Política de cookies</a>.
</p>
</div>
import { Component, OnInit } from "@angular/core";
import { CommentsClient } from "src/app/intafaces/comments-client";
import * as AOS from 'aos';

@Component({
  selector: "vex-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.scss"],

})
export class ClientsComponent implements OnInit{
 
  ngOnInit() {
    AOS.init();
  }

  sliders: CommentsClient[] = [
    {comment: "Sem duvida que estamos muito gratos pela vossa simpatia e satisfeitos com a qualidade do produto. Acabamentos perfeitos e entregas sem demoras, tudo a seu tempo. Iremos sem duvida ter em conta a vossa empresa aqundo da necessidade de produtos relacionados com o nosso ramo/hotelaria",
    company:  "Raul Santos | Head of Concierge" },
    {comment: "Atendimento espetacular e foram muito prestáveis a resolver o meu imprevisto. Recomendo muito.",
    company:  "Sabrina Saúde" },
    {comment: "O tempo de entrega foi ótimo. O material que comprámos era o que esperávamos.",
    company:  "anónimo" },
    {comment: "Nada a apontar. Entrega rápida e o material bem acondicionado.",
    company:  "anónimo" },

  ];
  
}

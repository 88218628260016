<div class="line"></div>
<div class="w-full grid grid-cols-2" id="footer">
  <div class="col_footer p-12">
    <img
      src="/assets/img/webp/pocentro-pt2020-feder-branco-bom-1.webp"
    />
    <p class="links mb-6"><a [href]="pdfPath" target="_blank">Ficha do Projeto</a> | <a href="/politica-privacidade">Políticas de Privacidade</a> </p>
    <a [href]="pdfPath2" target="_blank">
      <img
        src="/assets/img/webp/prr-inv.webp"
        width="380"
      />
    </a>
    <p class="links"><a [href]="pdfPath2" target="_blank" >Ficha do Projeto</a> | <a href="/politica-privacidade">Políticas de Privacidade</a> </p>
    <a href="https://www.livroreclamacoes.pt/INICIO/" target="_blank">
    <img
      class="mt-12"
      src="\assets\img\webp\livro-de-reclamacoes-1.webp"
    /></a>
  </div>
  <div class="col_footer p-12" id="info">
    <p id="tb">
      Rua Nossa Senhora de Fátima Nº31,<br />Caranguejeira<br />2420-124 Leiria
      <br />
      <br /><a href="mailto:info@wmg.com.pt">info@wmg.com.pt</a>
      <br />
      <br /><a href="tel:+351249159411">(+351) 249 159 411</a> <br /><a href="tel:+351249531505">(+351) 249 531 505</a>
    </p>
    <p class="tw">rede fixa nacional</p>

    <p class="tw mt-3">
      HORÁRIO DE ATENDIMENTO TELEFÓNICO <br />8H30 - 13H00 | 14H00 - 17H30
      <br><br>© {{ currentYear }} RED, Todos os direitos reservados.
    </p>
  </div>
</div>
<p></p>


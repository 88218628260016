import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContactsComponent } from "./components/contacts/contacts.component";
import { ArmazenamentoComponent } from "./pages/armazenamento/armazenamento.component";
import { ContactsPageComponent } from "./pages/contacts-page/contacts-page.component";
import { DecoracaoComponent } from "./pages/decoracao/decoracao.component";
import { EscritoriosComponent } from "./pages/escritorios/escritorios.component";
import { ExpositoresComponent } from "./pages/expositores/expositores.component";
import { HotelariaComponent } from "./pages/hotelaria/hotelaria.component";
import { LandingPageComponent } from "./pages/landing-page/landing-page.component";
import { PoliticaPrivacidadeComponent } from "./pages/politica-privacidade/politica-privacidade.component";
import { PoliticaCookiesComponent } from "./pages/politica-cookies/politica-cookies.component";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    children: [],
  },
  {
    path: "contactos",
    component: ContactsPageComponent,
    children: [],
  },
  {
    path: "armazenamento",
    component: ArmazenamentoComponent,
    children: [],
  },
  {
    path: "hotelaria",
    component: HotelariaComponent,
  },
  {
    path: "escritorios",
    component: EscritoriosComponent,
    children: [],
  },
  {
    path: "decoracao",
    component: DecoracaoComponent,
    children: [],
  },
  {
    path: "expositores",
    component: ExpositoresComponent,
    children: [],
  },
  {
    path: "politica-privacidade",
    component: PoliticaPrivacidadeComponent,
    children: [],
  },
  {
    path: "politica-cookies",
    component: PoliticaCookiesComponent,
    children: [],
  },
  { path: "404", component: LandingPageComponent }, // Página de erro personalizada
  { path: "**", component: LandingPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

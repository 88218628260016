<div id="container_services" class="grid grid-cols-3 gap-0 w-full">
  <div class="flex flex-col justify-center">
    <p class="ml-8" id="title">ONLINE RETAIL<br />BUSSINES</p>
    <p class="mt-4 ml-8" id="subtitle">soluções para a sua empresa</p>
  </div>
  <a
    class="movies"
    data-aos="zoom-in-up"
    data-aos-duration="1000"
    data-aos-anchor-placement="top-bottom"
    routerLink="armazenamento"
    id="armazenamento"
  >
    <video [muted]="'muted'" loop autoplay>
      <source src="/assets/video-mp4/armazenamento.mp4" type="video/mp4" />
    </video>
    <p class="buttonLable ml-8">armazenamento</p>
    <svg
      class="icon"
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.194 24.1087L16.2103 21.0537L22.6311 14.6328H0.186157V10.3672H22.6311L16.2103 3.94634L19.194 0.891312L30.8139 12.5L19.194 24.1087Z"
        fill="#1C1B1F"
      />
    </svg>
  </a>

  <a
    class="movies"
    data-aos="zoom-in-up"
    data-aos-duration="1000"
    data-aos-delay="200"
    data-aos-anchor-placement="top-bottom"
    routerLink="hotelaria"
    id="hotelaria"
  >
    <video [muted]="'muted'" loop autoplay>
      <source src="/assets/video-mp4/hotelaria.mp4" type="video/mp4" />
    </video>
    <p class="buttonLable">hotelaria</p>
    <svg
      class="icon"
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.194 24.1087L16.2103 21.0537L22.6311 14.6328H0.186157V10.3672H22.6311L16.2103 3.94634L19.194 0.891312L30.8139 12.5L19.194 24.1087Z"
        fill="#1C1B1F"
      />
    </svg>
  </a>

  <a
    class="movies"
    data-aos="zoom-in-up"
    data-aos-duration="1000"
    data-aos-delay="300"
    data-aos-anchor-placement="top-bottom"
    routerLink="expositores"
    id="expositores"
  >
    <video [muted]="'muted'" loop autoplay>
      <source src="/assets/video-mp4/expositores.mp4" type="video/mp4" />
    </video>
    <p class="buttonLable ml-8">expositores</p>
    <svg
      class="icon"
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.194 24.1087L16.2103 21.0537L22.6311 14.6328H0.186157V10.3672H22.6311L16.2103 3.94634L19.194 0.891312L30.8139 12.5L19.194 24.1087Z"
        fill="#1C1B1F"
      />
    </svg>
  </a>
  <a
    class="movies"
    data-aos="zoom-in-up"
    data-aos-duration="1000"
    data-aos-delay="400"
    data-aos-anchor-placement="top-bottom"
    routerLink="escritorios"
    id="escritorios"
  >
    <video [muted]="'muted'" loop autoplay>
      <source src="/assets/video-mp4/escritorios.mp4" type="video/mp4" />
    </video>
    <p class="buttonLable ml-8">escritórios</p>
    <svg
      class="icon"
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.194 24.1087L16.2103 21.0537L22.6311 14.6328H0.186157V10.3672H22.6311L16.2103 3.94634L19.194 0.891312L30.8139 12.5L19.194 24.1087Z"
        fill="#1C1B1F"
      />
    </svg>
  </a>
  <a
    class="movies"
    data-aos="zoom-in-up"
    data-aos-duration="1000"
    data-aos-delay="500"
    data-aos-anchor-placement="top-bottom"
    routerLink="decoracao"
    id="decoracao"
  >
    <video [muted]="'muted'" loop autoplay>
      <source src="/assets/video-mp4/decoracao.mp4" type="video/mp4" />
    </video>
    <p class="buttonLable ml-8">decoração</p>
    <svg
      class="icon"
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.194 24.1087L16.2103 21.0537L22.6311 14.6328H0.186157V10.3672H22.6311L16.2103 3.94634L19.194 0.891312L30.8139 12.5L19.194 24.1087Z"
        fill="#1C1B1F"
      />
    </svg>
  </a>
</div>

import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";

import * as THREE from "three";

// import BIRDS from "vanta/dist/vanta.birds.min";
import FOG from "vanta/dist/vanta.fog.min";
// import WAVES from "vanta/dist/vanta.waves.min";
// import CLOUDS from "vanta/dist/vanta.clouds.min";
// import CLOUDS2 from "vanta/dist/vanta.clouds2.min";
// import GLOBE from "vanta/dist/vanta.globe.min";
// import NET from "vanta/dist/vanta.net.min";
//import CELLS from "vanta/dist/vanta.cells.min";
// import TRUNK from "vanta/dist/vanta.trunk.min";
// import TOPOLOGY from "vanta/dist/vanta.topology.min";
// import DOTS from "vanta/dist/vanta.dots.min";
// import RINGS from "vanta/dist/vanta.rings.min";
// import HALO from "vanta/dist/vanta.halo.min";

@Component({
  selector: "vex-vantajs",
  templateUrl: "./vantajs.component.html",
  styleUrls: ["./vantajs.component.scss"],
})
export class VantajsComponent implements OnInit, OnDestroy {
  private vantaEffect: any;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    // this.buildVantaEffect();
    this.vantaEffect = FOG({
      el: this.el.nativeElement,
      highlightColor: 0x414141,
      midtoneColor: 0x111111,
      lowlightColor: 0x20202,
      baseColor: 0x141414,
      blurFactor: 0.62,
      speed: 0.90,
      zoom: 0.30,


      THREE: THREE,
    });
  }

  ngOnDestroy(): void {
    this.vantaEffect.destroy();
  }
}

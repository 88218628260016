import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "vex-decoracao",
  templateUrl: "./decoracao.component.html",
  styleUrls: ["./decoracao.component.scss"],
})
export class DecoracaoComponent implements OnInit {
  constructor(private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.setPageTitle(
      "WMG Retail Group - MadeDesign: Design e Inovação"
    );
    this.seoService.addMetaTags({
      description:
        "Descubra o melhor mobiliário de escritório com a MadeDesign. Qualidade e design combinam-se para oferecer soluções únicas. Comercialização do design em Portugal.",
      image_meta_title: "MadeDesign Design e Inovação",
    });

    AOS.init();
  }
}

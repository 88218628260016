import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import { SeoService } from "src/app/services/seo.service";
@Component({
  selector: "vex-armazenamento",
  templateUrl: "./armazenamento.component.html",
  styleUrls: ["./armazenamento.component.scss"],
})
export class ArmazenamentoComponent implements OnInit {
  constructor(private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.setPageTitle(
      "WMG Retail Group - Racks: Estantes Metálicas"
    );
    this.seoService.addMetaTags({
      description:
        "Encontre soluções de armazenamento ideais para maximizar o espaço e a eficiência no seu ambiente de trabalho. A Racks.pt oferece uma ampla gama de racks e prateleiras de alta qualidade, perfeitas para organizar seus produtos, equipamentos e materiais.",
      image_meta_title: "Racks Estantes Metálicas",
    });

    AOS.init();
  }
}

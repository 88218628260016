<div class="initSpace"></div>
<div class="space"></div>
<div data-aos="zoom-in-up" data-aos-duration="1000" data-aos-anchor-placement="top-center" class="divText">
    <h2 class="pt-4">Política de Cookies</h2>
    <p class="sobreText">

        <strong>Acerca desta política de cookies</strong>
        <br><br>
        Esta Política de Cookies explica o que são cookies e como os usamos, os tipos de cookies que usamos, ou seja, as
        informações que coletamos usando cookies e como essas informações são usadas e como controlar as preferências de
        cookies. Para obter mais informações sobre como usamos, armazenamos e mantemos os seus dados pessoais seguros,
        consulte nossa <a href="/politica-privacidade">Política de Privacidade</a>.
        <br><br>
        Você pode a qualquer momento alterar ou retirar seu consentimento da Declaração de Cookies do nosso site.
        <br><br>
        Saiba mais sobre quem somos, como você pode entrar em contato connosco e como processamos os dados pessoais em
        nossa Política de Privacidade.
        <br><br>
        O seu consentimento aplica-se aos seguintes domínios: red.com.pt
        <br><br>
        O seu estado actual: Não foi dado consentimento. <a href="">Gerir o seu consentimento</a>.
        <br><br>
       
        <strong> O que são cookies?</strong>
        <br><br>
        Cookies são pequenos arquivos de texto que são usados para armazenar pequenas informações. Eles são armazenados
        no seu dispositivo quando o site é carregado no seu navegador. Esses cookies ajudam-nos a fazer o site funcionar
        corretamente, torná-lo mais seguro, fornecer uma melhor experiência de utilizador, entender como o site
        funciona, analisar o que funciona e onde precisa ser melhorado.
        <br><br>
        <strong>Como usamos cookies?</strong>
        <br><br>
        Como a maioria dos serviços online, o nosso site usa cookies próprios e de terceiros para diversos fins. Os
        cookies primários são principalmente necessários para que o site funcione da maneira correta e não coletam
        nenhum dos seus dados de identificação pessoal.
        <br><br>
        Os cookies de terceiros usados no nosso site são principalmente para entender como o site funciona, como você
        interage com o nosso site, manter os nossos serviços seguros, fornecer anúncios relevantes para si e, em suma,
        fornecer-lhe uma melhor experiencia de utilizador e ajudar a acelerar as suas futuras interações com o nosso
        site.
        <br><br>
        <strong>Que tipos de cookies usamos?</strong>
        <br><br>
        Essenciais: Alguns cookies são essenciais para que possa experimentar todas as funcionalidades do nosso site.
        Eles nos permitem manter as sessões de utilizador e evitar quaisquer ameaças de segurança. Eles não coletam ou
        armazenam nenhuma informação pessoal.
        <br><br>
        Estatísticos: Esses cookies armazenam informações como o número de visitantes do site, o número de visitantes
        únicos, quais as páginas do site que foram visitadas, a fonte da visita etc. Esses dados ajudam-nos a entender e
        analisar o desempenho do site e onde precisa de melhorias.
        <br><br>
        Funcionais: São os cookies que auxiliam algumas funcionalidades não essenciais no nosso site. Essas
        funcionalidades incluem incorporar conteúdo como vídeos ou compartilhar conteúdo do site em plataformas de mídia
        social.
        <br><br>
        Preferências: Esses cookies nos ajudam a armazenar as suas configurações e preferências de navegação, como
        preferências de idioma, para que tenha uma experiência melhor e eficiente em futuras visitas ao site.
        <br><br>
        A lista abaixo detalha os cookies usados no nosso site.
    </p>
    <div class="table-container">
    <table>
        <tr>
          <th>cookie</th>
          <th>descrição</th>
        </tr>
        <tr>
            <td>
                _GRECAPTCHA	
            </td>
            <td>
                Este cookie é definido pelo serviço recaptcha do Google para identificar bots para proteger o site contra ataques de spam maliciosos. 
            </td>
        </tr>
        <tr>
            <td>
                cookielawinfo-checkbox-analytics	
            </td>
            <td>
                Definido pelo plug-in RGPD Cookie Consent para armazenar o consentimento do usuário para cookies na categoria "Analíticos".
            </td>
        </tr>
        <tr>
            <td>
                cookielawinfo-checkbox-functional	
            </td>
            <td>
                Definido pelo plug-in RGPD Cookie Consent para armazenar o consentimento do usuário para cookies na categoria "Funcional".
            </td>
        </tr>
        <tr>
            <td>
                cookielawinfo-checkbox-necessary	
            </td>
            <td>
                Definido pelo plug-in GDPR Cookie Consent para armazenar o consentimento do usuário para cookies na categoria "Necessário". 
            </td>
        </tr>
        <tr>
            <td>
                cookielawinfo-checkbox-others
            </td>
            <td>
                Definido pelo plug-in RGPD Cookie Consent para armazenar o consentimento do usuário para cookies na categoria "Outros".
            </td>
        </tr>
        <tr>
            <td>
                cookielawinfo-checkbox-performance
            </td>
            <td>
                Definido pelo plug-in RGPD Cookie Consent para armazenar o consentimento do usuário para cookies na categoria "Desempenho".
            </td>
        </tr>
        <tr>
            <td>
                PHPSESSID
            </td>
            <td>
                Este cookie é nativo de aplicativos PHP. O cookie é usado para armazenar e identificar o ID de sessão exclusivo de um usuário com a finalidade de gerenciar a sessão do usuário no site. O cookie é um cookie de sessão e é excluído quando todas as janelas do navegador são fechadas.
            </td>
        </tr>
        <tr>
            <td>
                viewed_cookie_policy
            </td>
            <td>
                O cookie é definido pelo plug-in RGPD Cookie Consent e é usado ou não usado para usar o uso de cookies definido. Ele não armazena nenhum dado pessoal.
            </td>
        </tr>
        </table>
        </div>
    <p class="sobreText">
        <strong>Como posso controlar as preferências de cookies ?</strong>
        <br><br>
        Caso decida alterar suas preferências posteriormente durante sua sessão de navegação, pode clicar no link “Gerir
        o seu consentimento.” no topo desta página. Isso exibirá o aviso de consentimento novamente, permitindo que
        altere as suas preferências ou retire totalmente seu consentimento.
<br><br>
        Além disso, diferentes navegadores fornecem métodos diferentes para bloquear e excluir cookies usados por sites.
        Pode alterar as configurações do seu navegador para bloquear/excluir os cookies. Para saber mais sobre como
        gerir e excluir cookies, visite wikipedia.org, www.allaboutcookies.org.
    </p>
</div>
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EmailService } from "src/app/services/email.service";
import * as AOS from "aos";

@Component({
  selector: "vex-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent {
  isLoading = false;
  mensagem = false;
  erro = false;

  bg = true;

  form: FormGroup = this.formBuilder.group({
    honeypot: [],
    name: ["", Validators.required],
    email: ["", [Validators.required, Validators.email]],
    message: ["", Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService
  ) {}

  send() {
    if (this.form.valid) {
      this.isLoading = true;
      console.log(this.form);

      this.emailService
        .sendEmail(this.form.value)
        .then((response) => {
          // Handle success response
          console.log("Email sent:", response);
          this.form.reset();
          Object.keys(this.form.controls).forEach((key) => {
            this.form.controls[key].setErrors(null);
          });
          this.isLoading = false;
          this.mensagem = true;
        })
        .catch((error) => {
          // Handle error
          console.error("Failed to send email:", error);
          this.erro = true;
          this.form.reset();
          Object.keys(this.form.controls).forEach((key) => {
            this.form.controls[key].setErrors(null);
          });
          this.isLoading = false;
        });
    }
  }

  closeMensage() {
    this.mensagem = false;
    this.erro = false;
  }
}

import { Component, OnInit } from "@angular/core";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "vex-contacts-page",
  templateUrl: "./contacts-page.component.html",
  styleUrls: ["./contacts-page.component.scss"],
})
export class ContactsPageComponent implements OnInit {
  constructor(private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.setPageTitle(
      "Soluções B2B: Contactos Gerais da WMG - Retail Group"
    );
    this.seoService.addMetaTags({
      description:
        "Impulsione o seu negócio de  com soluções B2B de alta qualidade. Entre em Contacro com a WMG - Retail Group e  transforme o seu negócio.",
      // image: "",
      image_meta_title: "Contactos Gerais da WMG - Retail Group",
    });
  }
}

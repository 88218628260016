import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'vex-politica-privacidade',
  templateUrl: './politica-privacidade.component.html',
  styleUrls: ['./politica-privacidade.component.scss']
})
export class PoliticaPrivacidadeComponent {


}
